<template>
      <!-- MUTE PARTICIPANT sendcommandone muteParticipant participantUserId -->
  <div
    id="video-container"
    :class="{
      'd-none': hideVideoContainer,
    }"
  >
    <v-btn
      v-if="showDebug"
      color="primary"
      style="z-index: 10000; position: fixed"
      @click="showRecorderDebug()"
    >
     Debug
    </v-btn>
    <VideoBridgeContentGrid
      :isRecorder="isRecorder"
      :hasPrivilege="userHasPrivilege"
      :showTimeLine="showTimeLine"
      :userIsOneCall="userOneCall"
    />
 <!-- START RECORDER ELEMENT CLONE -->
    <div class="sidenav d-none recorderBar" id="recorderBar">
      <v-btn icon color="white" class="togglePlay tooltipped">
        <font-awesome-icon
          :icon="['fal', 'play']"
          :style="{ fontSize: '20px' }"
        />
          <font-awesome-icon
          :icon="['fal', 'spinner']"
          :style="{ fontSize: '20px' }"
          class="d-none fa-spin"
        />
         <font-awesome-icon
          class="d-none"
          :icon="['fal', 'pause']"
          :style="{ fontSize: '20px' }"
        />
        <span class="tooltippedtooltip ttside sidePlayStop">{{ $t("components.bridgeCallTooltips.playStop") }}</span>
      </v-btn>
       <v-btn icon color="white" class="toggleStop tooltipped">
        <font-awesome-icon
          :icon="['fal', 'stop']"
          :style="{ fontSize: '20px' }"
        />
       <span class="tooltippedtooltip ttside sideStopStream">{{ $t("components.bridgeCallTooltips.stopStream") }}</span>
      </v-btn>
      <v-btn icon color="white" class="openRecorder tooltipped"  >
        <font-awesome-icon
          :icon="['fal', 'external-link']"
          :style="{ fontSize: '20px' }"
        />
      <span class="tooltippedtooltip ttside sideViewRec">{{ $t("components.bridgeCallTooltips.showRecorder") }}</span>
      </v-btn>
      <!-- <v-btn icon color="white" class="" @click="navigateToStream()" >
        <font-awesome-icon
          :icon="['fal', 'signal-stream']"
          :style="{ fontSize: '20px' }"
        />
      </v-btn>    -->
        <v-btn
          color="white"
          icon
          class="toggleSettings tooltipped"
        >
          <font-awesome-icon
            :icon="['fal', 'cog']"
            :style="{ fontSize: '20px' }"
          />
          <span class="tooltippedtooltip ttside sideSettings">{{ $t("components.bridgeCallTooltips.streamSettings") }}</span>
        </v-btn>
    </div>
    <!-- START RECORDER ELEMENT CLONE -->
    <!-- START VIDEO ELEMENT CLONE -->
    <video class="d-none" autoplay loop id="localVideo" playsinline/>
    <!-- END VIDEO ELEMENT CLONE -->
    <!-- START AUDIO ELEMENT CLONE -->
    <!-- <audio class="d-none" autoplay muted loop id="localVideo" /> -->
    <!-- END AUDIO ELEMENT CLONE -->
    <!--START STATIC CONTENT TO CLONE -->
    <div class="tile videoBridgeWrapper d-none" id="contentElement">
      <div class="topbar">
        <v-tooltip top>
          <template #activator="{ on }">
            <div class="userIconInfo tooltipped"  v-on="on">
              <!-- <font-awesome-icon
                class="userIconTile"
                v-on="on"
                :icon="['fal', 'user-crown']"
                :style="{ fontSize: '20px' }"
              /> -->
               <span  class=" userIconTile font-weight-light">
                {{ $t("generics.user") }}
              </span>
              <span  class=" d-none guestLabelTile font-weight-light">
                {{ $t("components.userSettings.guest") }}
              </span>
              <!-- <span class="tooltippedtooltip  ttcamera">{{ $t("components.remotePersonCall.user") }}</span> -->
            </div>
          </template>
          <span>{{ $t("components.remotePersonCall.user") }}</span>
      </v-tooltip>
        <!-- <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon color="white" class="moderatorIconInfo d-none tooltipped"  v-on="on">
              <font-awesome-icon
                v-on="on"
                :icon="['fal', 'users-crown']"
                :style="{ fontSize: '20px' }"
              />
              <span class="tooltippedtooltip  ttcamera">{{ $t("components.remotePersonCall.moderator") }}</span>
            </v-btn>
          </template>
          <span>{{ $t("components.remotePersonCall.moderator") }}</span>
      </v-tooltip> -->
      <span  class="moderatorIconInfo d-none">{{ $t("components.remotePersonCall.moderator") }}</span>
        <p class="callerName nameNormal"></p>
        <input type="text" class="inputTopbar d-none" />
      </div>
      <div class="iconbar"></div>
    </div>
    <div class="tile d-none" id="localData">
      <div class="topbar">
        <p class="callerName nameNormal"></p>
        <input type="text" class="inputTopbar d-none" />
      </div>
      <div class="iconbar" id="localIconbar"></div>
      <video id="vid0" autoplay muted loop />
    </div>
    <!-- Start low quality connection -->
  <div id="qualityLevel" class="qualityLevel d-none">{{ $t("components.bridgeCallTooltips.badQuality") }}</div>
    <!-- End low quality connection -->
    <!-- Start deafen  TT -->
    <div id="deafen" class="qualityLevel d-none">{{ $t("components.bridgeCallTooltips.deafenTT") }}</div>
    <!-- End deafen TT -->
    <div id="iconBarLocalStream" class="iconBarLocalStream d-none">

      <v-tooltip top >
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="requestPayment tooltipped" v-on="on">
            <!-- <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '20px' }" v-if="currency === 'EUR'"/>
            <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '20px' }" v-if="currency === 'GBP'"/>
            <v-img
            contain
            height="20"
            v-if="currency === 'CHF'"
            src="img/paymentIcons/swiss-franc.png"
            lazy-src="img/paymentIcons/swiss-franc.png"
          ></v-img> -->
           <img
              class="currency"
              src="img/icons/facepay.svg"
              lazy-src="img/icons/facepay_white.svg"
            />
             <span class="tooltippedtooltip  ttcamera">{{ $t('components.callsContent.requestPayment') }}</span>
          </v-btn>
        </template>
        <span>{{ $t('components.callsContent.requestPayment') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleVideo tooltipped" v-on="on">
            <img
              class="video"
              src="/img/camera.svg"
            />
            <img
              class="video-slash d-none"
              src="/img/camera-red.svg"
            />
            <span class="tooltippedtooltip  ttcamera">{{ $t("generics.camera") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("generics.camera") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleMic tooltipped" v-on="on">
            <div class="progressBarBridge">
              <div class="progressBarLevelBridge"></div>
            </div>
            <font-awesome-icon
              :icon="['fal', 'microphone']"
              :style="{ fontSize: '20px' }"
            />
            <img
              class="microphone-slash d-none"
              src="/img/micro-red.svg"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.mic") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.mic") }}</span>
      </v-tooltip>
      <!-- START mute all button -->
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleMuteAll tooltipped" v-on="on">
            <img
              class="muteAllButon"
              src="/img/muteAllBridge.svg"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.muteAllTT") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.muteAllTT") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleMuteAllRed tooltipped" v-on="on">
            <img
              class="muteAllButon"
              src="/img/muteAllBridgeRed.svg"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.unmuteAllParticipants") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.unmuteAllParticipants") }}</span>
      </v-tooltip>
      <!-- END mute all button -->
      <!-- START mute all button -->
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleMuteAudio tooltipped" v-on="on">
            <font-awesome-icon
              :icon="['fal', 'volume-up']"
              :style="{ fontSize: '20px' }"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.muteAllParticipants") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.muteAllParticipants") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleMuteAudioSlash tooltipped" v-on="on">
            <font-awesome-icon
              :icon="['fal', 'volume-slash']"
              :style="{ fontSize: '20px' }"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.unmuteAllParticipants") }}</span>
         s </v-btn>
        </template>
        <span>{{ $t("components.callsContent.unmuteAllParticipants") }}</span>
      </v-tooltip>
       <!-- END mute all button -->
      <!-- START need to be manipulated -->
      <v-tooltip top >
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleRemoteMic tooltipped" v-on="on">
             <div class="progressBarBridge">
              <div class="progressBarLevelBridge"></div>
            </div>
            <font-awesome-icon
              :icon="['fal', 'microphone']"
              :style="{ fontSize: '20px' }"
            />
            <img
              class="microphone-slash d-none"
              src="/img/micro-red.svg"
            />
            <span class="tooltippedtooltip ttmike">{{ $t("components.callsContent.mic") }}</span>
          </v-btn>

        </template>
      </v-tooltip>
      <!-- END need to be manipulated -->
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            color="white"
            class="toggleFullScreen tooltipped"
            target="_blank"
            v-on="on"

          >
            <font-awesome-icon
              :icon="['fal', 'expand-wide']"
              :style="{ fontSize: '20px' }"
            />
             <span class="tooltippedtooltip ttfulls">{{ $t("generics.fullscreen") }}</span>
          </v-btn>
        </template>

      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            color="white"
            class="toggleCloseFullScreen tooltipped"
            target="_blank"
            v-on="on"
          >
            <font-awesome-icon
              :icon="['fal', 'border-all']"
              :style="{ fontSize: '20px' }"
            />
             <span class="tooltippedtooltip ttfulls">{{ $t("generics.normalScreen") }}</span>
          </v-btn>
        </template>
      </v-tooltip>

       <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            color="white"
            class="toggleMaximizeScreen tooltipped"
            target="_blank"
            v-on="on"
          >
            <font-awesome-icon
              :icon="['fas', 'rectangle-wide']"
              :style="{ fontSize: '20px' }"
            />
             <span class="tooltippedtooltip ttfulls">{{ $t("generics.maximizeScreen") }}</span>
          </v-btn>
        </template>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="toggleMessage tooltipped"
            v-on="on"
            icon
            color="white"
          >
            <!-- @click="setShowModalSenMsg(person.uuid, 'message')" -->
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '20px' }"
            />
            <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
            <span style="font-size:14px" class="tooltippedtooltip ttmssg">{{ $t("components.userProfile.sendAMessage")}}</span>
          </v-btn>
        </template>

      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn
            class="toggleSendEmail tooltipped"
            v-on="on"
            icon
            color="white"
            target="_blank"
          >
           <font-awesome-icon  color="white" :icon="['fal', 'envelope-square']" :style="{ fontSize: '20px' }" />
            <span style="font-size:14px" class="tooltippedtooltip ttemail">{{ $t('components.userListItem.sendEMail') }}</span>
             </v-btn>
        </template>
      </v-tooltip>

      <!-- START GIVE WORD FUNCTIONALITY -->
      <!-- hand moving in request word -->
      <!-- v-if="isOnRequest && (showHandFor[uuid] && showHandFor[uuid].showHand === true)" -->
      <!-- <div class="waveHandDiv">
        <span class="waveHand">
          <font-awesome-icon color="white" :icon="['fas', 'hand-paper']" class="iconHand" />
        </span>
      </div> -->
      <!-- request word -->
        <!-- <v-tooltip
          top
        >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="white" icon class="mx-3">
            <font-awesome-icon :icon="['fal', 'hand-point-up']" color="white" :style="{ fontSize: '20px' }" />
          </v-btn>
        </template>
        <span>{{$t('components.remotePersonCall.requestView')}}</span>
      </v-tooltip> -->

      <!-- give word -->
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on }"> -->
            <!-- :value="isOnRequest" -->
          <!-- <v-badge
            avatar
            overlap
            color="primary"
            :offset-y="15"
            :offset-x="23"
          >
          <template v-slot:badge>
            <font-awesome-icon :icon="['fal', 'exclamation']" :style="{ fontSize: '19px', padding: 1 }" />
          </template>
          <template> -->
              <!-- :class="{'blink': isOnRequest}" -->
            <!-- <v-btn
              v-on="on"
              icon
              color="white"
              class="mx-3"
            >
              <font-awesome-icon :icon="['fal', 'comment']" :style="{ fontSize: '24px' }" />
            </v-btn>
          </template>
          </v-badge>
        </template>
        <span>{{$t('components.callsContent.giveWord')}}</span>
      </v-tooltip> -->
      <!-- END GIVE WORD FUNCTIONALITY -->

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleHangUp tooltipped" v-on="on">
            <font-awesome-icon
              :icon="['fal', 'phone']"
              class="faPhoneRotate"
              :style="{ fontSize: '20px' }"
            />
            <span class="tooltippedtooltip ttphone">{{ $t("generics.hangUp") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("generics.hangUp") }}</span>
      </v-tooltip>
      <v-tooltip top>

        <template #activator="{ on }">
          <v-btn icon color="white" class="rejectCall tooltipped" v-on="on">
            <font-awesome-icon
              :icon="['fal', 'phone']"
              class="faPhoneRotate"
              :style="{ fontSize: '20px' }"
            />
             <span class="tooltippedtooltip ttphone">{{ $t("generics.hangUp") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("generics.hangUp") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="toggleRotateCamera tooltipped" v-on="on">
            <img
              class="rotateCameraButton"
              src="/img/rotateCamera.svg"
            />
            <span class="tooltippedtooltip ttmike"></span>
          </v-btn>
        </template>
        <span></span>
      </v-tooltip>
      <v-tooltip top>

        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="white" icon class="toggleShareScreen tooltipped">
            <font-awesome-icon
              :icon="['fal', 'desktop']"
              :style="{ fontSize: '20px' }"
            />
            <!-- custom slash icon, we need to know if sharing is on to show this icon -->
            <font-awesome-icon
              class="toggleShareScreenSlash"
              :icon="['fal', 'slash']"
              :style="{
                fontSize: '20px',
                position: 'absolute',
                top: -1,
                left: 5,
              }"
            />
              <span class="toggleShareTT tooltippedtooltip ttshare">{{$t('components.callsContent.shareScreen')}}</span>
              <!-- <span  class="tooltippedtooltip ttshare">{{ $t("components.callsContent.shareScreen") }}</span> -->

          </v-btn>

        </template>
        <span>{{ $t("components.callsContent.shareScreen") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="white" icon class="toggleModerator tooltipped">
            <font-awesome-icon
              :icon="['fal', 'users-crown']"
              :style="{ fontSize: '20px' }"
            />
            <span class="tooltippedtooltip ttadmin">{{ $t("components.remotePersonCall.transferModerator") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("components.remotePersonCall.transferModerator") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            color="white"
            class="togglePresentation tooltipped"
            target="_blank"
            v-on="on"
          >
            <img class="imgButton" src="/img/icons/voffice_viewB.svg" />
             <span class="tooltippedtooltip ttpresent">{{ $t("components.bridgeCallTooltips.presentation") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("generics.fullscreen") }}</span>
      </v-tooltip>
       <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            color="white"
            class="manageConference tooltipped"
            target="_blank"
            v-on="on"
          >
             <font-awesome-icon
              :icon="['fal', 'users']"
              :style="{ fontSize: '20px' }"
            />
             <span class="tooltippedtooltip ttpresent">{{$t("generics.manageConference")}}</span>

             <v-list class="listAttendees d-none">

              <v-list-item dense v-for="user in conferenceAttendees" :key="user.uuid">
                <v-list-item-content>
                  <v-list-item-title>
                  {{user.name}}
                  <div class="d-flex dbtnsAttendees" v-if="user.uuid !== ownUUID">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <span class="tooltippedButtonMenu">
                          <font-awesome-icon
                            :icon="['fal', 'door-open']"
                            :style="{ fontSize: '20px' }"
                            class="mr-2 inIcon greenButton"
                            :id="`inIcon-${user.uuid}`"
                            v-on="on"
                          />
                          <span class="tooltippedtooltipButton ttdoor">{{$t("generics.addUser")}}</span>
                        </span>
                      </template>
                      <span>{{$t("generics.addUser")}}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <span class="tooltippedButtonMenu">
                           <font-awesome-icon
                              :icon="['fal', 'door-closed']"
                              :style="{ fontSize: '20px' }"
                              class="ml-2 outIcon tooltipped"
                              :id="`outIcon-${user.uuid}`"
                              v-on="on"
                            />
                          <span class="tooltippedtooltipButton ttdoor">{{$t("generics.removeUser")}}</span>
                        </span>
                      </template>
                      <span>{{$t("generics.removeUser")}}</span>
                    </v-tooltip>
                  </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-btn>
        </template>
        <span>{{$t("generics.manageConference")}}</span>
      </v-tooltip>
       <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="toggleMultiMessage tooltipped"
            v-on="on"
            icon
            color="white"
          >
            <!-- @click="setShowModalSenMsg(person.uuid, 'message')" -->
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '20px' }"
            />
            <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
            <span style="font-size:14px" class="tooltippedtooltip ttmssg">{{ $t("components.bridgeCallTooltips.messageAll") }}</span>
          </v-btn>
        </template>

      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon color="white" class="btnDocs tooltipped"  v-on="on">
            <font-awesome-icon
              v-on="on"
              :icon="['fal', 'file-pdf']"
              :style="{ fontSize: '20px' }"
            />
            <span class="tooltippedtooltip  ttcamera">{{ $t("components.bridgeCallTooltips.documents") }}</span>
            <v-list class="listDocuments d-none">
              <v-list-item dense v-for="document in conferenceDocuments" :key="document.documentId">
                <v-list-item-title>
                <a :href="document.documentURL" target="_blank">{{document.documentName}}</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-btn>
        </template>
        <span>{{ $t("components.bridgeCallTooltips.documents") }}</span>
      </v-tooltip>

      <!-- <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="toggleSelectiveMute tooltipped"
            v-on="on"
            icon
            color="white"
          >
            <font-awesome-icon
            :icon="['fal', 'microphone-stand']"
            :style="{ fontSize: '20px' }"></font-awesome-icon>
            <span style="font-size:14px" class="tooltippedtooltip ttmssg">{{ $t("components.bridgeCallTooltips.muteAllBut") }}</span>
          </v-btn>
        </template>
      </v-tooltip> -->

      <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="white" class="toggleGiveWord tooltipped">
              <font-awesome-icon :icon="['fal', 'comment']" :style="{ fontSize: '20px' }" />
              <span class="toggleGiveWordBadge d-none">
                <font-awesome-icon :icon="['fal', 'exclamation']" :style="{ fontSize: '19px', padding: 1 }" />
              </span>
              <span style="font-size:14px" class="tooltippedtooltip ttmssg">{{$t('components.callsContent.giveWord')}}</span>
            </v-btn>
          </template>
          <span>{{$t('components.callsContent.giveWord')}}</span>
      </v-tooltip>

      <!-- <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-badge
              avatar
              overlap
              color="primary"
              :offset-y="15"
              :offset-x="23"
              :value="false"
              class="toggleGiveWordBadge"
            >
            <template v-slot:badge>
              <font-awesome-icon :icon="['fal', 'exclamation']" :style="{ fontSize: '19px', padding: 1 }" />
            </template>
            <template>
              <v-btn
                v-on="on"
                icon
                color="white"
                class="toggleGiveWord"
              >
                <font-awesome-icon :icon="['fal', 'comment']" :style="{ fontSize: '24px' }" />
              </v-btn>
            </template>
            </v-badge>
          </template>
          <span>{{$t('components.callsContent.giveWord')}}</span>
      </v-tooltip> -->

      <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="white" class="toggleWaveHand tooltipped">
              <font-awesome-icon :icon="['fal', 'hand-point-up']" :style="{ fontSize: '20px' }" />
              <span style="font-size:14px" class="tooltippedtooltip ttmssg">{{ $t("components.bridgeCallTooltips.raiseHand") }}</span>
            </v-btn>
          </template>
          <span>{{$t('components.remotePersonCall.requestView')}}</span>
      </v-tooltip>
      <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="white" class="toggleWaveHandFilled">
              <font-awesome-icon :icon="['fas', 'hand-point-up']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{$t('components.remotePersonCall.requestView')}}</span>
      </v-tooltip>

    </div>
    <!-- TEMPLATE USER IMAGE -->
    <div id="localImage" class="localUserImage d-none">
      <img
        class="userImageBridge avatar"
        src="img/default_profile_picture.png"
      />
    </div>
    <!-- END TEMPLATE USER IMAGE -->
    <!-- TEMPLATE COMPANY LOGO -->
    <div id="companyLogo" class="companyLogo d-none">
      <img
        class="companyLogoBridge avatar"
        src="img/icon.png"
      />
    </div>
    <!-- END TEMPLATE COMPANY LOGO  -->
    <!-- TEMPLATE MUTE MICROPHONE ICON BLINK  -->
    <div id="microphoneSlashOverlay" class="microphoneSlashOverlay d-none">
     <img
        class="microphone-slash-overlay microphone-slash-overlay-blink"
        src="/img/micro-full-red.svg"
      />
    </div>
    <!-- END TEMPLATE MUTE MICROPHONE ICON BLINK  -->
    <!-- END STATIC CONTENT TO CLONE -->
  </div>
</template>
<script>
// import store from "../../store";
import JitsiMeetJS from "../../jitsi/JitsiMeetJS";
import { TRACK_AUDIO_LEVEL_CHANGED } from "../../jitsi/JitsiTrackEvents";
import VideoBridgeContentGrid from "../../components/content/videoBridgeContentGrid.vue";
import { isMobile } from "../../lib/mobileUtil";
import MD5 from '../../lib/md5';
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: { VideoBridgeContentGrid },
  props: [
    "isRecorder",
    "ownUUID",
    "getCallUUID",
    "getAvatarForUuid",
    "getTitelForUuid",
    "getNameForUuid",
    "getVisitorFunctionForUuid",
    "jvbOptions",
    "hangUpCallingCb",
    "rejectCallCb",
    "showDebug",
    "isNormalMode",
    "hideVideoContainer",
    "videoDeviceId",
    "audioDeviceId",
    "amIInitiator",
    "assistantsInitiatorList",
    "amICalling",
    "showSendMessage",
    "getEmailForUuid",
    "emailSalutation",
    "openCloseBridgeStreamSettings",
    "getStreamData",
    "getModerator",
    "currentCallTime",
    "setCallDuration",
    "bridgeCallEnd",
    "redirectToStartView",
    "toggleModalSettings",
    "companyLogo",
    "hasPrivilege",
    "userIsOneCall",
    "setSendMessageInCallModal",
    "setNewMessageFromCall",
    "newMessageFromCall",
    "fetchWallPaperSrc",
    "resetNewMessageFromCallEvent",
    "conferenceDocuments",
    "userConferencePosition",
    "conferenceAttendees",
    "dispatchMessage",
    "callUserConference",
    "conferenceSettings",
    "usersToHideName",
    "showTimeLine",
    "isConferenceCall",
    "updateAttendanceList",
    "isVisitor",
    "isWaitingRoomUser",
    "isPayEnabled",
    "currency",
    "setRequestPayment",
    "bridgeCallScreenShareState",
    "isAudioOnly",
    "hasMobileParticipant",
    "hasCallEnded",
    "setInfoModal",
    "allMessages",
    "confModerators",
    // "participantInPayment",
    // "uuidInPayment",
    // "setUserInPayment",
    // "setAttach",
    // "isVisitorfromPayment"
  ],
  data() {
    return {
      // state: store.state,
      recorderParticipantId: null,
      findDivToAttachPromise: null,
      shuffleGridPromise: null,
      room: null,
      connection: null,
      desktopRoom: null,
      desktopConnection: null,
      activeUsersProp: 0,
      previousGridSize: "2x2",
      gridSize: "2x2",
      gridHasChanged: false,
      micMute: false,
      videDisabled: false,
      lockAudioToggle: true,
      lockVideoToggle: true,
      maxUserSize: 2,
      currentMode: 20,
      activeUsers: 0,
      currentMax: 1,
      currentMin: 1,
      presenterMode: 0,
      presenterModeInitiatedBy: null,
      implicitPresenterMode: false,
      gridKeyByIndex: {},
      disconnectInProgress: false,
      callInitiator: undefined,
      shareScreenMode: 0,
      currentDesktop: undefined,
      dropModOnNewMod: false,
      showStreamSettings: false,
      streamState: 'cleared',
      spaceShortcutCallAcceptTimeout: null,
      wavingHand: false,
      wordGivenTo: null,
      currentCallingState: [],
      toBeRemovedFromCallState: {},
      removeCheckerInterval: null,
      participantsMuted: {},
      ctrlPressed: false,
      volumeMuted: false,
      audioOutputMuted: false,
      buttonClicked: false,
      maximizedTile: null,
      positionOfFullScreenArray: 0,
      mobileCamera: 'user',
      // participantInPayment: null,
      // uuidInPayment: null,
    };
  },
  watch: {
    streamState: {
      handler: function (val) {
      const stopButton = document.getElementsByClassName('toggleStop')[0]
      if(val !== 'cleared' && val !== 'stopped'){
        if(stopButton){
          stopButton.classList.remove('d-none');
        }
      } else {
        if(stopButton){
          stopButton.classList.add('d-none');
        }
      }
      },
    },
    "bridgeCallEnd": {
      handler: function (isEnd) {
        if(isEnd){
          this.rejectFullCall();
        }
      },
    },
    "currentCallTime": {
       handler: function(currentTime) {
        // console.log('CURRENT TIME INTO MANAGER FILE', currentTime);
        if(this.room) this.room.sendTextMessage(JSON.stringify({cmd:"cmdCurrentTime",value:currentTime}));
       },
    },
    amICalling: {
      handler: function(amICalling) {
        for(const uuid of this.currentCallingState){
          if(! amICalling.includes(uuid)){
            this.toBeRemovedFromCallState[uuid]=Date.now();
          }
        }
        var newcallstate = [];
        for(const uuid of amICalling){
          newcallstate.push(uuid);
          if(! this.currentCallingState.includes(uuid)){
            if(this.toBeRemovedFromCallState[uuid]) {
              delete this.toBeRemovedFromCallState[uuid]
            }else{
              const sortKey = this.sortKeyBuilder(
                false,
                false,
                this.getNameForUuid(uuid),
                uuid
              );
              const aName = this.getNameForUuid(uuid);
              const title = this.getTitelForUuid(uuid);
              const visitorFunction = this.getVisitorFunctionForUuid(uuid);
              const fullNameDisplay = (title == '' || title == 'unknown')
                ? aName + ' ' + `${visitorFunction || ''}`
                : (title + ' ' + aName);
              this.gridAdd(uuid, fullNameDisplay, sortKey);
              this.addAvatarToPlaceholder(uuid);
              this.addTextToPlaceholder(uuid);
            }
          }
        }

        this.currentCallingState = newcallstate;
      },
      deep: true,
    },
    videoDeviceId: {
      handler: async function (videoDeviceId) {
        if (this.room) {
          this.lockVideoToggle = true;
          for (const track of this.room.getLocalTracks()) {
            if (track.getType() === "video") {
              const tile = document.getElementById(this.room.myUserId());
              const videoElement = tile.getElementsByTagName("video")[0];
              track.detach(videoElement);
              videoElement.remove();
              await this.room.removeTrack(track);
              track.dispose();
            }
          }
          console.log("switch selected cam:", videoDeviceId);
          JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            cameraDeviceId: videoDeviceId,
          })
            .then((tracks) => {
              this.onLocalTracks(tracks);
            })
            .catch((error) => {
              throw error;
            });
        }
      },
      deep: true,
    },
    audioDeviceId: {
      handler: async function (audioDeviceId) {
        if (this.room) {
          this.lockAudioToggle = true;
          for (const track of this.room.getLocalTracks()) {
            if (track.getType() === "audio") {
              const tile = document.getElementById(this.room.myUserId());
              const audioElement = tile.getElementsByTagName("audio")[0];
              track.detach(audioElement);
              audioElement.remove();
              await this.room.removeTrack(track);
              track.dispose();
            }
          }
          console.log("switch selected mic:", audioDeviceId);
          JitsiMeetJS.createLocalTracks({
            devices: ["audio"],
            micDeviceId: audioDeviceId,
          })
            .then((tracks) => {
              this.onLocalTracks(tracks);
            })
            .catch((error) => {
              throw error;
            });
        }
      },
      deep: true,
    },
    newMessageFromCall:{
       handler:function (newMessageFromCall) {
          // console.log('newMessageFromCall', newMessageFromCall)
          const myId = this.room.myUserId();
          if(myId && this.isUserModerator(myId) && newMessageFromCall){
            this.room.sendTextMessage(JSON.stringify({cmd:"cmdSendMultiUserMessage",value:newMessageFromCall}));
          }
       }
    }
  },
  async beforeDestroy() {
    if(this.removeCheckerInterval){
      clearInterval(this.removeCheckerInterval);
      this.removeCheckerInterval=null;
    }
    // console.log('destroy page');
    if (this.desktopRoom) {
      const toRemove = this.desktopRoom.getLocalTracks();
      for (const track of toRemove) {
        await this.desktopRoom.removeTrack(track);
        track.dispose();
        // console.log('desktop track disposed');
      }
      if (this.desktopRoom.isJoined()) await this.desktopRoom.leave();
      this.desktopRoom = null;
      console.log('desktop room left');
    }
    if (this.desktopConnection) {
      if (
        this.desktopConnection.xmpp.connection &&
        !this.desktopConnection.xmpp.disconnectInProgress
      ) {
        await this.desktopConnection.disconnect();
        // console.log('desktop connection dropped');
      }else{
        console.warn('desktop connection already dropped');
      }
      this.desktopConnection = null;
    }
    if (this.room) {
      const toRemove = this.room.getLocalTracks();
      for (const track of toRemove) {
        await this.room.removeTrack(track);
        track.dispose();
        // console.log('track disposed');
      }
      if (this.room.isJoined()) await this.room.leave();
      console.log('room left');
      this.room = null;
    }
    if (this.connection) {
      if (
        this.connection.xmpp.connection &&
        !this.connection.xmpp.disconnectInProgress
      ) {
        await this.connection.disconnect();
        // console.log('connection dropped');
      }else{
        console.warn('connection already dropped');
      }
      this.connection = null;
    }else{
      console.warn('connection already gone');
    }
  },
  mounted() {
    // console.log('normalMode ', this.isNormalMode);
    // console.log('ownUUID ', this.ownUUID);
    // console.log('conf attendees mounted ', this.conferenceAttendees);
    // console.log('Pre moderators ', this.confModerators);

    document.addEventListener('fullscreenchange', this.onFullScreenChange, {
     capture: true,
     passive: true,
    });

    document.addEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    this.removeCheckerInterval = setInterval(this.removeChecker, 1000);
    this.conference();

    if (!this.isNormalMode){
      this.switchGrid(1);
    }
  },
  destroyed() {
    document.removeEventListener("fullscreenchange", this.onFullScreenChange, {
      capture: true,
      passive: true,
    });

    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    document.removeEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    this.redirectToStartView();
    this.resetNewMessageFromCallEvent()
  },
  methods: {
    showRequestPaymentButton(){
      const participants = this.room.getParticipants();
      if ( participants.length > 0 ){
        for(const participant of participants){
          const id = participant.getId();
          const objParticipant = this.room.getParticipantById(id);
          const userData = objParticipant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);

          const requestPaymentButton = document.getElementById(`requestPayment-${id}`);
          if ((this.isWaitingRoomUser(personId) || this.isVisitor(personId, true))){
            if ( requestPaymentButton ){
              requestPaymentButton.classList.remove("d-none");
            }
          }
        }
      }
    },
    onFullScreenChange(event){
       if (!document.fullscreenElement) {
         if (this.room.myUserId() === this.maximizedTile){
           this.switchScreenSize(this.maximizedTile, 'normal')
          }  else {
             this.switchScreenSize(this.maximizedTile, 'maximize')
          }
      }
    },
    activateLeaveButton(){
      // console.log('activate leave button');
      const participants = this.room.getParticipants();
      // console.log('participants', participants);
      let personsOffline;
      const allLeaveButtons = []
      const allJoinButtons = [];
      if ( participants.length > 0 ){
        for(const participant of participants){
          const id = participant.getId();
          const objParticipant = this.room.getParticipantById(id);
          const userData = objParticipant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          personsOffline = this.conferenceAttendees.filter(e => e.id !== personId);
          const leaveButton = document.getElementById(`outIcon-${personId}`);
          const joinButton = document.getElementById(`inIcon-${personId}`);
          if ( leaveButton ){
            const joinBtnObj = {
              button: joinButton,
              participant: participant,
              id: id
            }
            const leaveBtnObj = {
              button: leaveButton,
              participant: participant,
              id: id
            }
            allJoinButtons.push(joinBtnObj)
            allLeaveButtons.push(leaveBtnObj)
          }
        }
        // console.log('allLeaveButtons', allLeaveButtons);
        if ( allLeaveButtons.length > 0 ){
          allLeaveButtons.forEach(button => {
            button.button.classList.add('greenButton');
            button.button.removeEventListener('click', this.leaveButtonClick, false);
            button.button.addEventListener('click', () => {

              if ( this.room.getParticipantById(button.id) ){
                this.leaveButtonClick(button.button, button.participant, button.id)
              }
            }), false;
          })

        }
        if ( allJoinButtons.length > 0 ){
          allJoinButtons.forEach(button => {
            button.button.classList.remove('greenButton');
            button.button.setAttribute('data-tile', button.id);
            button.button.removeEventListener('click', this.joinButtonClick, false)
          })
        }
      }else{
          if ( this.conferenceAttendees && this.conferenceAttendees.length > 0){
            personsOffline = this.conferenceAttendees;
          }
      }

      if (personsOffline && personsOffline.length > 0 ){
        personsOffline.forEach(person => {
          const joinButtonPersonOffline = document.getElementById(`inIcon-${person.uuid}`);
          if ( joinButtonPersonOffline ){
            joinButtonPersonOffline.removeEventListener('click', this.joinButtonClick, false)
            if ( !hasOwn.call(joinButtonPersonOffline, 'onclick') ){
              joinButtonPersonOffline.addEventListener('click', () => {this.joinButtonClick(joinButtonPersonOffline, person.uuid)}, false)
            }
          }
        })
      }
    },

    leaveButtonClick(leaveButton, participant, id){
      // console.log('leaveButtonClick', leaveButton, participant, id);
      // console.log('buttonClicked', this.buttonClicked)
      if ( !this.buttonClicked ){
        this.buttonClicked = true;
        if ( leaveButton.classList.contains('greenButton') ){
          if ( participant.isModerator() ){
            this.dispatchMessage(this.$t("generics.cannotKickAdmin"));
          }else{
            this.leaveConference(id);
          }
        }else{
          this.dispatchMessage(this.$t("generics.notInConference"));
        }

        setTimeout(() => {
          this.buttonClicked = false
        }, 1000)
      }
    },
    activateJoinButton(participantId){
      if(this.ownUUID == "recorder") return;
        // console.log('participantId activate join button', participantId)
        const objParticipant = this.room.getParticipantById(participantId);
        let userData;
        let personId;
        if ( objParticipant ){

          userData = objParticipant.getDisplayName();
          personId = this.getUUIDForDisplayName(userData);
        }else{
          const allInIcons = document.getElementsByClassName('inIcon');
          allInIcons.forEach(element => {
            const userUUID = element.getAttribute('data-tile');
            if ( userUUID && userUUID == participantId ){
              const tempId = element.getAttribute('id');
              personId = tempId.replace('inIcon-','').trim();
              //personId = element.getAttribute('id').split('-')[1];
              // console.log('son iguales', personId)
            }
          });
        }
        // console.log('personId  ', personId)
        const leaveButton = document.getElementById(`outIcon-${personId}`);
        const joinButton = document.getElementById(`inIcon-${personId}`);

        if ( joinButton ){
          joinButton.classList.add('greenButton');
          leaveButton.classList.remove('greenButton');

          leaveButton.removeEventListener('click', this.leaveButtonClick, false);
          joinButton.removeEventListener('click', this.joinButtonClick, false)
          // console.log('join ', joinButton,personId)
          if ( !hasOwn.call(joinButton, 'onclick') ){
            joinButton.addEventListener('click', () => {this.joinButtonClick(joinButton,personId)}, false);
          }
        }

    },
    joinButtonClick(joinButton, personId){
      // console.log('join button click')
      if ( !this.buttonClicked ){
        this.buttonClicked = true;
        if ( joinButton.classList.contains('greenButton') ){
          this.enterConference(personId);
        }else{
          this.dispatchMessage(this.$t("generics.alreadyInConference"));
        }

        setTimeout(() => {
          this.buttonClicked = false;
        }, 1000)
      }
    },
    enterConference(uuid){
      this.callUserConference(uuid)
    },
    leaveConference(participantId){
      this.activateJoinButton(participantId);
      this.toggleHangUp(participantId);
    },
    updateTextTopBar(id){
      // console.log('id =====> ', id)
      const inputTopbar = document.getElementById('inputTopbar-' + id);
      const callerName = document.getElementById('callerName-' + id);
      callerName.classList.add('d-none');
      inputTopbar.classList.remove('d-none');
      inputTopbar.focus();
      inputTopbar.addEventListener('blur', () => {
      inputTopbar.classList.add('d-none');
      callerName.classList.remove('d-none');
        if ( inputTopbar.value != '' && inputTopbar.value !== callerName.innerHTML ){
          callerName.innerHTML = inputTopbar.value;
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdUpdateUserName",value:inputTopbar.value,toWho:id}));
        }
      })
    },
    hangUpCalling(id){
      if (this.hangUpCallingCb) {
        this.hangUpCallingCb(id);
      } else {
        console.warn('No hangUpCallingCb registered');
      }
    },
    reproduceDingDong(){
      const audio = new Audio('/media/notificationSound.mp3');
      return audio.play().catch((error) => console.warn('Failed to play notification audio', error));
    },
    toggleCamMic() {
      this.toggleModalSettings();
    },
    rejectFullCall(){
      if(this.room) {
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdEndCall"}));
        this.redirectToStartView();
      }
    },
    removeChecker(){
      for(const uuid of Object.keys(this.toBeRemovedFromCallState)){
        if(Date.now() - this.toBeRemovedFromCallState[uuid] > 5000) {
          delete this.toBeRemovedFromCallState[uuid];
          this.gridRemove(uuid);
          if (this.activeUsers < 2) {
            if(! this.disconnectInProgress) this.rejectCall();
          }
        }
      }
    },
    async toggleHangUp(participantId){
      if (this.room) {
        if (this.room.getParticipantById(participantId).isModerator()){
           await this.room.takeMod(participantId);
        }
        this.room.kickParticipant(participantId);
      }
    },
    getParticipantRoom(participantId){
      const objectKeyData = Object.keys(this.room.getParticipants());
      for (let i = 0; i < objectKeyData.length; i++) {
        const index = objectKeyData[i];
        const participant = this.room.getParticipants()[index]
        const id = participant.getId()
        if (id == participantId){
          return participant;
        }
      }
    },
    showRecorderDebug(){
      window.open(
        "//" +
          window.location.hostname +
          ":" +
          window.location.port +
          "/recorder.html#" +
          this.getCallUUID
      );
    },
    showRecorder(){
      var streamLink;
      if(!this.getStreamData.streamLink ||this.getStreamData.streamLink == ''){
        streamLink = MD5(this.getCallUUID+'linksalt');
      }
      else{
        streamLink = this.getStreamData.streamLink;
      }
      window.open(
        "//" +
          window.location.hostname +
          ":" +
          window.location.port +
          "/player.html#" +
          streamLink
      );
    },
    fetchMyWallPaperSrc(uuid){
      return this.fetchWallPaperSrc(uuid)
    },
    onStreamStateCommand(val) {
      // console.log('onStreamStateCommand '+val.value);
      this.streamState = val.value;
      if(val.value == 'paused') {
        if (this.ownUUID == "recorder"){
          Array.from(document.querySelectorAll('audio')).forEach(function(audio) {
            audio.muted = true;
          });
          const wallpaper = document.getElementById('imgWallpaper');
          if(! wallpaper) {
            const image = document.createElement('img')
            image.setAttribute('src', val.wallpaperSrc);
            image.setAttribute('id', 'imgWallpaper');
            image.classList.add('bridgeWallpaper');
            const parent = document.getElementById('video-container').parentNode;
            if (parent) parent.prepend(image);
          } else {
            wallpaper.setAttribute('src', val.wallpaperSrc);
          }
        } else {
          // for everybody else change the outline red ( border )
          const container = document.getElementById('contentWrapper')
          container.classList.remove('recorderOn');
          document.getElementById('pausedButtonWrapper').classList.remove('d-none');
          document.getElementById('recButtonWrapper').classList.add('d-none');
        }
      }
      else if(val.value == 'streaming') {
        if (this.ownUUID == "recorder"){
          console.log('resume');
          for(const audio of Array.from(document.querySelectorAll('audio'))) {
            const participantId = audio.getAttribute('id').split('-')[1];
            if(this.participantsMuted[participantId] == true){
              audio.muted = true
            }else{
              audio.muted = false
            }
          }
          const wallpaper = document.getElementById('imgWallpaper');
          if(wallpaper) wallpaper.remove();
        } else {
          const container = document.getElementById('contentWrapper')
          container.classList.add('recorderOn');
          document.getElementById('pausedButtonWrapper').classList.add('d-none');
          document.getElementById('recButtonWrapper').classList.remove('d-none');
        }
      }
      else if(val.value == 'stopped') {
        if (this.ownUUID == "recorder"){
          this.rejectCall();
          return;
        }
        if(this.room.isModerator()) {
          // console.log('send cmdStreamState cleared');
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdStreamState",value:'cleared'}));
        }
        //update state in onUserLeft(recorder)
      }
    },
    toggleWaveHand() {
      // console.log('toggleWaveHand()');
      if(this.wavingHand == true) {
        this.wavingHand = false;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdWaveHand",value:false,user:this.room.myUserId()}));
        //change my icon to normal
        document.getElementById('toggleWaveHandFilled-'+this.room.myUserId()).classList.add('d-none')
        document.getElementById('toggleWaveHand-'+this.room.myUserId()).classList.remove('d-none')
      }else{
        this.wavingHand = true;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdWaveHand",value:true,user:this.room.myUserId()}));
        //change my icon to waving
        document.getElementById('toggleWaveHandFilled-'+this.room.myUserId()).classList.remove('d-none')
        document.getElementById('toggleWaveHand-'+this.room.myUserId()).classList.add('d-none')
      }
    },
    toggleGiveWord(participantId) {
      if(participantId == this.room.myUserId() || this.room.getParticipantById(participantId).isModerator()){
        // console.log('move initiator to mod')
        this.wordGivenTo = null;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdGiveWord",user:null}));
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:1,initiator:participantId}));
        this.onPresenterModeCommand({value:1,initiator:participantId});
      }else if((participantId != this.room.myUserId())&&(!this.wordGivenTo || participantId != this.wordGivenTo)) {
        // console.log('give word to '+participantId)
        this.wordGivenTo = participantId;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdGiveWord",user:participantId}));
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdWaveHand",value:false,user:participantId}));
      }else{
        // console.log('clear give word')
        this.wordGivenTo = null;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdGiveWord",user:null}));
      }
    },
    onWaveHandCommand(participantId,value){
      if(this.ownUUID == "recorder") return;
      if(value == true) {
        if(! this.room.isModerator()) return;
        // poner bocadillo con notificacion
        // document.getElementById('toggleWaveHandFilled-'+participantId).classList.remove('d-none')
        // toggleGiveWord
        document.getElementById('toggleGiveWord-'+participantId).getElementsByClassName('toggleGiveWordBadge')[0].classList.remove('d-none')
        document.getElementById('toggleGiveWord-'+participantId).classList.add('toggleGiveWordBlink')
        const iconBar = document.getElementById('iconBar-'+participantId);
        if(iconBar)
          iconBar.parentElement.classList.add('fadeOut');
        this.reproduceDingDong();
      }else{
        if(this.room.myUserId() == participantId) this.wavingHand=false;
        // document.getElementById('toggleWaveHandFilled-'+participantId).classList.add('d-none')
        document.getElementById('toggleGiveWord-'+participantId).getElementsByClassName('toggleGiveWordBadge')[0].classList.add('d-none')
        document.getElementById('toggleGiveWord-'+participantId).classList.remove('toggleGiveWordBlink')
        const iconBar = document.getElementById('iconBar-'+participantId);
        if(iconBar)
          iconBar.parentElement.classList.remove('fadeOut');
      }
    },
    onGiveWordCommand(participantId){
      this.wordGivenTo = participantId;
      if(participantId != null || this.presenterMode==1) {
        this.switchGrid(0);
        this.switchGrid(1);
      }else{
        this.switchGrid(1);
        this.switchGrid(0);
      }

      if(this.ownUUID == "recorder") return;

      if(! this.room.isModerator() && !isMobile()){
        if(!this.wordGivenTo || this.wordGivenTo == this.room.myUserId()){
          document.getElementById('toggleWaveHand-'+this.room.myUserId()).classList.add('d-none');
          document.getElementById('toggleWaveHandFilled-'+this.room.myUserId()).classList.add('d-none');
        }else{
          document.getElementById('toggleWaveHand-'+this.room.myUserId()).classList.remove('d-none');
          document.getElementById('toggleWaveHandFilled-'+this.room.myUserId()).classList.add('d-none');
        }
        return;
      }
      this.updateButtonBars();
    },
    toggleSendEmail(tileId){
      const objParticipant = this.room.getParticipantById(tileId);
      const userData = objParticipant.getDisplayName();
      const personId = this.getUUIDForDisplayName(userData);
      const userEmail = this.getEmailForUuid(personId);
      const mailData = `mailto:${userEmail}?subject=${this.getNameForUuid(this.ownUUID)}%20from%20vOffice&body=${this.emailSalutation()}%20${this.getNameForUuid(personId)},`
      document.getElementById(`toggleSendEmail-${tileId}`).setAttribute('href', mailData)
      window.open(mailData,'_blank');
    },
    toggleMessage(tileId){
      const objParticipant = this.room.getParticipantById(tileId);
      const userData = objParticipant.getDisplayName();
      const personId = this.getUUIDForDisplayName(userData)
      this.showSendMessage(personId, 'regularMsg');
      // call to open modal message setShowModalSenMsg(person.uuid, 'message')
    },
    customTest(){
    },
    // navigateToStream () {
    //   console.log('Nav to Stream')
    // },
    openStreamSettings(){
      this.openCloseBridgeStreamSettings();
    },
    togglePlay(tileId){
      const buttonToToggle = document.getElementById(`togglePlay-${tileId}`);
      const playIcon = buttonToToggle.getElementsByClassName("fa-play")[0];
      const pauseIcon = buttonToToggle.getElementsByClassName("fa-pause")[0];
      const spinnerIcon = buttonToToggle.getElementsByClassName("fa-spinner")[0]
      spinnerIcon.classList.remove('d-none')
      playIcon.classList.add('d-none')
      pauseIcon.classList.add('d-none')
      //const stopButton = document.getElementById(`toggleStop-${tileId}`);
      //stopButton.disabled = true;
      if (this.streamState == 'streaming'){
        const imgSrc = this.fetchMyWallPaperSrc(this.ownUUID)
        playIcon.classList.remove('d-none')
        pauseIcon.classList.add('d-none')
        spinnerIcon.classList.add('d-none')
        this.streamState = 'paused';
        // console.log('send cmdStreamState paused');
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdStreamState",value:'paused', wallpaperSrc:imgSrc}));

      } else if (this.streamState == 'paused'){
        playIcon.classList.add('d-none')
        spinnerIcon.classList.add('d-none')
        pauseIcon.classList.remove('d-none')
        this.streamState = 'streaming';
        // console.log('send cmdStreamState streaming');
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdStreamState",value:'streaming'}));

      } else if (this.streamState == 'stopped' || this.streamState == 'cleared'){
        this.startStreaming();
        // stateupdate from recorderJoined
      }
    },
    toggleStop(tileId){
      this.room.kickParticipant(this.recorderParticipantId);
      // stateupdate from recorderLeft
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    gridRemove(tileId) {
      if (this.activeUsers == 1) return;
      if (!document.getElementById(tileId)) return;
      document.getElementById(tileId).remove();
      let i = this.activeUsers;
      for (var j in this.gridKeyByIndex) {
        if (this.gridKeyByIndex[j].id == tileId) i = parseInt(j) + 1;
      }
      for (; i < this.activeUsers; i++) {
        $("#call-grid-" + this.currentMode + "-" + i).append(
          $("#call-grid-" + this.currentMode + "-" + (i + 1))
            .children(":first")
            .detach()
        );
        this.gridKeyByIndex[i - 1] = this.gridKeyByIndex[i];
      }
      delete this.gridKeyByIndex[i - 1];
      this.activeUsers--;
      if (this.activeUsers < this.currentMin) {
        this.switchGrid(this.presenterMode);
      }
    },
    createContentElement(key, title = "", userUUID) {
      const contentElement = document
        .getElementById("contentElement")
        .cloneNode(true);
      if(!isMobile()) {
        contentElement.addEventListener('mouseenter', e => {
          const iconBar = document.getElementById('iconBar-'+key);
          if(iconBar)
            iconBar.parentElement.classList.add('fadeOut');
        });
        contentElement.addEventListener('mouseleave', e => {
          const iconBar = document.getElementById('iconBar-'+key);
          if(iconBar)
            iconBar.parentElement.classList.remove('fadeOut');
        });
      } else {
        const iconBar = document.getElementById('iconBar-'+key);
        if(iconBar)
          iconBar.parentElement.classList.add('fadeOut');
      }
      contentElement.setAttribute("id", key);
      contentElement.classList.remove("d-none");
      contentElement.getElementsByClassName("moderatorIconInfo")[0].setAttribute('id', 'moderatorIconInfo-' + key);
      if(! isMobile()) contentElement.getElementsByClassName("userIconInfo")[0].setAttribute('id', 'userIconInfo-' + key);
      if(isMobile()) contentElement.getElementsByClassName("userIconInfo")[0].classList.add('d-none');
      contentElement.getElementsByClassName("callerName")[0].setAttribute('id', 'callerName-' + key);
      contentElement.getElementsByClassName("inputTopbar")[0].setAttribute('id', 'inputTopbar-' + key);
      if(! isMobile()) contentElement.getElementsByClassName("userIconTile")[0].setAttribute('id', 'userIconTile-' + key);
      if(! isMobile()) contentElement.getElementsByClassName("guestLabelTile")[0].setAttribute('id', 'guestLabelTile-' + key);
      // console.log('Title on createContentElement', title)
      if (this.usersToHideName.indexOf(userUUID) !== -1){
       title = " ";
      }
      contentElement.getElementsByClassName("callerName")[0].innerHTML = title || "";
      contentElement.getElementsByClassName("inputTopbar")[0].setAttribute('value', title || "");

        contentElement.getElementsByClassName("callerName")[0].addEventListener("click", () => {
          if ( this.isUserModerator(this.room.myUserId()) ){
            this.updateTextTopBar(key);
          }
        });
      return contentElement;
    },
    switchGrid(presenterMode) {
      // console.log('switchGrid pr:'+presenterMode);
      this.presenterMode = presenterMode;
      const oldMode = this.currentMode;
      if(this.maximizedTile) {
          this.currentMode = 20;
      } else if (presenterMode == 1) {
        if (this.activeUsers < 2) {
          this.currentMode = 19;
          this.currentMax = 1;
          this.currentMin = 1;
        } else if (this.activeUsers < 6) {
          this.currentMode = 1;
          this.currentMax = 5;
          this.currentMin = 2;
        } else if (this.activeUsers < 7) {
          this.currentMode = 21;
          this.currentMax = 6;
          this.currentMin = 6;
        } else if (this.activeUsers < 8) {
          this.currentMode = 6;
          this.currentMax = 7;
          this.currentMin = 7;
        } else if (this.activeUsers < 10) {
          this.currentMode = 2;
          this.currentMax = 9;
          this.currentMin = 8;
        } else if (this.activeUsers < 11) {
          this.currentMode = 22;
          this.currentMax = 10;
          this.currentMin = 10;
        } else if (this.activeUsers < 12) {
          this.currentMode = 7;
          this.currentMax = 11;
          this.currentMin = 11;
        } else if (this.activeUsers < 14) {
          this.currentMode = 3;
          this.currentMax = 13;
          this.currentMin = 12;
        } else {
          this.currentMode = 4;
          this.currentMax = 21;
          this.currentMin = 14;
        }
      } else {
        if (this.activeUsers < 2) {
          this.currentMode = 20;
          this.currentMax = 1;
          this.currentMin = 1;
        } else if (this.activeUsers < 3) {
          if (isMobile()){
            this.currentMode = 23;
          }else{
            this.currentMode = 5;
          }
          this.currentMax = 2;
          this.currentMin = 2;
        } else if (this.activeUsers < 4) {
          this.currentMode = 9;
          this.currentMax = 3;
          this.currentMin = 3;
        } else if (this.activeUsers < 5) {
          this.currentMode = 10;
          this.currentMax = 4;
          this.currentMin = 4;
        } else if (this.activeUsers < 6) {
          this.currentMode = 8;
          this.currentMax = 5;
          this.currentMin = 5;
        } else if (this.activeUsers < 7) {
          this.currentMode = 11;
          this.currentMax = 6;
          this.currentMin = 6;
        } else if (this.activeUsers < 9) {
          this.currentMode = 12;
          this.currentMax = 8;
          this.currentMin = 7;
        } else if (this.activeUsers < 10) {
          this.currentMode = 13;
          this.currentMax = 9;
          this.currentMin = 9;
        } else if (this.activeUsers < 11) {
          this.currentMode = 14;
          this.currentMax = 10;
          this.currentMin = 10;
        } else if (this.activeUsers < 13) {
          this.currentMode = 15;
          this.currentMax = 12;
          this.currentMin = 11;
        } else if (this.activeUsers < 16) {
          this.currentMode = 16;
          this.currentMax = 15;
          this.currentMin = 13;
        } else if (this.activeUsers < 17) {
          this.currentMode = 17;
          this.currentMax = 16;
          this.currentMin = 16;
        } else {
          this.currentMode = 18;
          this.currentMax = 20;
          this.currentMin = 17;
        }
      }

      if (oldMode == this.currentMode) return;

      for (var index in this.gridKeyByIndex) {
        if ( presenterMode == 1 ) {
          this.gridKeyByIndex[index].sortKey =
            ((this.gridKeyByIndex[index].id == this.maximizedTile)?"0":"1") +
            ((this.gridKeyByIndex[index].id == this.wordGivenTo)?"0":"1") +
            this.gridKeyByIndex[index].sortKey.substr(2, 1) +
            ((this.gridKeyByIndex[index].id == this.presenterModeInitiatedBy)?"0":"1") +
            "0" +
            this.gridKeyByIndex[index].sortKey.substr(5, 1) +
            "0" +
            this.gridKeyByIndex[index].sortKey.substr(6);
        } else {
          this.gridKeyByIndex[index].sortKey =
            ((this.gridKeyByIndex[index].id == this.maximizedTile)?"0":"1") +
            ((this.gridKeyByIndex[index].id == this.wordGivenTo)?"0":"1") +
            this.gridKeyByIndex[index].sortKey.substr(2, 1) +
            "1" +
            "1" +
            this.gridKeyByIndex[index].sortKey.substr(5, 1) +
            "1" +
            this.gridKeyByIndex[index].sortKey.substr(7);
        }
      }

      var insertPositions = [];
      for (const index of Object.keys(this.gridKeyByIndex).sort())
        insertPositions.push({
          index: parseInt(index),
          value: this.gridKeyByIndex[index],
        });

      insertPositions.sort(function (a, b) {
        if (a.value.sortKey < b.value.sortKey) {
          return -1;
        }
        if (a.value.sortKey > b.value.sortKey) {
          return 1;
        }
        return 0;
      });

      this.gridKeyByIndex = {};
      for (let i = 0; i < this.activeUsers; i++)
        if (insertPositions[i]) this.gridKeyByIndex[i] = insertPositions[i].value;

      // console.log('gridKeyByIndex after reassign',JSON.stringify(this.gridKeyByIndex,null,2));

      for (let i = 0; i < this.activeUsers; i++) {
        if (insertPositions[i]) {
          $(
            "#call-grid-" +
              this.currentMode +
              "-" +
              (i + 1)
          ).append(
            $("#call-grid-" + oldMode + "-" + (insertPositions[i].index + 1))
              .children(":first")
              .detach()
          );
          $("#call-grid-" + oldMode + "-" + (insertPositions[i].index + 1)).text("");
        }
      }

      $(".call-grid").addClass("hidd");
      $("#call-grid-" + this.currentMode).removeClass("hidd");
    },
    gridReplace(tileIdOld, tileId, displayName, sortKey) {
      document.getElementById(tileIdOld).remove();
      let i = this.activeUsers;
      for (var j in this.gridKeyByIndex) {
        if (this.gridKeyByIndex[j].id == tileIdOld) i = parseInt(j) + 1;
      }
      this.gridKeyByIndex[i-1]= { id: tileId, sortKey: sortKey };

      const objParticipant = this.room.getParticipantById(tileId);
      let userToDisplay = displayName;
      let userUUID;
      if(objParticipant){
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        userUUID = personId;
        if ( this.userConferencePosition && this.userConferencePosition[personId] ){
          userToDisplay = displayName + ' - ' + this.userConferencePosition[personId];
        }
      }else{
          userUUID = this.ownUUID;
          if ( this.userConferencePosition && this.userConferencePosition[this.ownUUID] ){
          userToDisplay = displayName + ' - ' + this.userConferencePosition[this.ownUUID];
        }
      }
        $("#call-grid-" + this.currentMode + "-" + i).append(
          this.createContentElement(tileId, userToDisplay, userUUID)
        );
    },
    gridAdd(tileId, displayName, sortKey) {
      if (this.activeUsers == 20 && this.presenterMode == 0) return;
      if (this.activeUsers == 21) return;
      this.activeUsers++;
      if (this.activeUsers > this.currentMax) {
        this.switchGrid(this.presenterMode);
      }
      var i = this.activeUsers;
      for (; i > 1; i--) {
        if (sortKey < this.gridKeyByIndex[i - 2].sortKey) {
          $("#call-grid-" + this.currentMode + "-" + i).append(
            $("#call-grid-" + this.currentMode + "-" + (i - 1))
              .children(":first")
              .detach()
          );
          this.gridKeyByIndex[i - 1] = this.gridKeyByIndex[i - 2];
        } else {
          break;
        }
      }
      let userToDisplay = displayName;
      let userUUID;
      if ( this.room ){
        const objParticipant = this.room.getParticipantById(tileId);
        if(objParticipant){
          const userData = objParticipant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          userUUID = personId;
          if ( this.userConferencePosition && this.userConferencePosition[personId] ){
            userToDisplay = displayName + ' - ' + this.userConferencePosition[personId];
          }
        }else{
          userUUID = this.ownUUID;
          if ( this.userConferencePosition && this.userConferencePosition[this.ownUUID] ){
            userToDisplay = displayName + ' - ' + this.userConferencePosition[this.ownUUID];
          }
        }
      }

      this.gridKeyByIndex[i - 1] = { id: tileId, sortKey: sortKey };
      $("#call-grid-" + this.currentMode + "-" + i).append(
        this.createContentElement(tileId, userToDisplay, userUUID)
      );
      // if ( this.isVisitorfromPayment && userUUID === this.ownUUID){
      //   this.setAttach(tileId)
      // }
    },
    dumpDebug() {
      if (this.room) {
        console.log("--------------------------\nLocalTracks:");
        for (const track of this.room.getLocalTracks()) {
          console.log(track.getType() + " track " + track.getTrackId());
        }
        console.log("Participants:");
        for (const participant of this.room.getParticipants()) {
          console.log(
            this.extractNameFromUserData(participant.getDisplayName())
          );
          for (const track of participant.getTracks()) {
            console.log(" " + track.getType() + " track " + track.getTrackId());
          }
        }
        console.log("--------------------------");
      }
    },
    startStreaming() {
      // play rec cues
      // console.log(JSON.stringify(this.getStreamData));
      let streamUrl = this.getStreamData.streamUrl;
      if (streamUrl && streamUrl.length > 0){
        streamUrl = streamUrl.replace(/\/$/, "");
      }
      var rtmp = streamUrl+'/'+this.getStreamData.streamKey;
      if (rtmp == 'undefined/undefined' ||rtmp == '/' || this.getStreamData.internalPlayerOnly) rtmp = '';
      var streamLink;
      if(!this.getStreamData.streamLink ||this.getStreamData.streamLink == ''){
        streamLink = MD5(this.getCallUUID+'linksalt');
      }
      else{
        streamLink = this.getStreamData.streamLink;
      }
      $.ajax({
        type: "POST",
        url: this.jvbOptions.recorder+"start_stream",
        data: {key:window.location.hostname+'.'+MD5(streamLink),url:'https://'+window.location.hostname+'/recorder.html#'+this.getCallUUID,rtmp:rtmp},
        success: function(data){
          if ( data == 'ok' ){
            // all good,
          } else {
            // check result status code 200 ok, 503 out of slots (recorder), 500 rtmp url already in use
          }
        },
      });
    },
    addUserImage(tileId, userUUID) {
      const tileElement = document.getElementById(tileId);
      const imgDiv = document.getElementById("localImage").cloneNode(true);
      imgDiv.setAttribute("id", `userImage-${tileId}`);
      const img = imgDiv.getElementsByClassName("userImageBridge")[0];
      img.setAttribute("src", this.getAvatarForDisplayName(userUUID));
      img.addEventListener("click", () => {
        if ( !isMobile() ){
          this.toggleFullscreenRoutine(tileId)
        }
      });
      tileElement.appendChild(imgDiv);
    },
    getUserImage(id) {
      const userImage = document.getElementById("userImage-" + id);
      if (userImage) {
        return userImage;
      }
    },
    addCompanyLogo(tileId, isLocal){
      const tileElement = document.getElementById(tileId);
      const companyLogo =document.getElementById('companyLogo').cloneNode(true);
      companyLogo.setAttribute('id', `companyLogo-${tileId}`)
      const img = companyLogo.getElementsByClassName('companyLogoBridge')[0];
      img.setAttribute('src', this.companyLogo)
      const objParticipant = this.room.getParticipantById(tileId);
      if(objParticipant){
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        if(personId && this.hasPrivilege(personId)){
          companyLogo.classList.remove('d-none')
        }
      }else {
        if(this.hasPrivilege(this.ownUUID)){
          companyLogo.classList.remove('d-none')
        }
      }
      tileElement.appendChild(companyLogo)
    },
    addMicrophoneSlashOverlay(tileId, isLocal){
      const tileElement = document.getElementById(tileId);
      const microphoneSlashOverlay =document.getElementById('microphoneSlashOverlay').cloneNode(true);
      microphoneSlashOverlay.setAttribute('id', `microphoneSlashOverlay-${tileId}`)
      tileElement.appendChild(microphoneSlashOverlay)
    },
    addBar(tileId, isLocal) {
      if(tileId == this.room.myUserId() && isMobile()) return;
      const tileElement = document.getElementById(tileId);
      if(tileId != this.room.myUserId() && isMobile()) tileId = this.room.myUserId();
      const bar = document.getElementById("iconBarLocalStream").cloneNode(true);
      const qualitySymbol = document.getElementById("qualityLevel").cloneNode(true);
      const deafenText = document.getElementById("deafen").cloneNode(true);
      //deafenText.textContent = $t("components.bridgeCallTooltips.deafenTT")
      qualitySymbol.setAttribute("id", `quality-${tileId}`);
      deafenText.setAttribute("id", `deafen-${tileId}`);
      tileElement.appendChild(qualitySymbol)
      tileElement.appendChild(deafenText)
      bar.setAttribute("id", `iconBar-${tileId}`);
      bar.classList.remove("d-none");
      tileElement.getElementsByClassName("iconbar")[0].appendChild(bar);
      const recorderBar = document.getElementById("recorderBar").cloneNode(true);
      recorderBar.setAttribute("id", `recBar-${tileId}`);
      recorderBar.classList.add('d-none');
      tileElement.appendChild(recorderBar);
      recorderBar.getElementsByClassName("togglePlay")[0]
        .setAttribute("id", `togglePlay-${tileId}`);
      recorderBar.getElementsByClassName("toggleStop")[0]
        .setAttribute("id", `toggleStop-${tileId}`);
      recorderBar.getElementsByClassName("openRecorder")[0]
      .setAttribute("id", `openRecorder-${tileId}`);
      document
        .getElementById(`toggleStop-${tileId}`)
        .classList.add("d-none");
      document
        .getElementById(`toggleStop-${tileId}`)
        .addEventListener("click", () => {
          this.toggleStop(tileId);
        });
      document.getElementById(`openRecorder-${tileId}`)
       .addEventListener("click", () => {
          this.showRecorder();
        });
      recorderBar.getElementsByClassName("toggleSettings")[0]
      .setAttribute("id", `toggleSettings-${tileId}`);
        document
        .getElementById(`toggleSettings-${tileId}`)
        .addEventListener("click", () => {
          this.openStreamSettings(tileId);
        });
      document
        .getElementById(`togglePlay-${tileId}`)
        .addEventListener("click", () => {
          this.togglePlay(tileId);
        });
        bar
        .getElementsByClassName("toggleWaveHandFilled")[0]
        .setAttribute("id", `toggleWaveHandFilled-${tileId}`);
       bar
        .getElementsByClassName("toggleWaveHand")[0]
        .setAttribute("id", `toggleWaveHand-${tileId}`);
      bar
        .getElementsByClassName("toggleGiveWord")[0]
        .setAttribute("id", `toggleGiveWord-${tileId}`);
      bar
        .getElementsByClassName("toggleMessage")[0]
        .setAttribute("id", `toggleMessage-${tileId}`);
      bar
        .getElementsByClassName("toggleSendEmail")[0]
        .setAttribute("id", `toggleSendEmail-${tileId}`);
      bar
        .getElementsByClassName("rejectCall")[0]
        .setAttribute("id", `rejectCall-${tileId}`);
      bar
        .getElementsByClassName("toggleHangUp")[0]
        .setAttribute("id", `toggleHangUp-${tileId}`);
      bar
        .getElementsByClassName("toggleMic")[0]
        .setAttribute("id", `toggleMic-${tileId}`);
      bar
        .getElementsByClassName("btnDocs")[0]
        .setAttribute("id", `btnDocs-${tileId}`);
      bar
        .getElementsByClassName("listDocuments")[0]
        .setAttribute("id", `listDocuments-${tileId}`);
      bar
        .getElementsByClassName("toggleRemoteMic")[0]
        .setAttribute("id", `toggleRemoteMic-${tileId}`);
      bar
        .getElementsByClassName("toggleVideo")[0]
        .setAttribute("id", `toggleVideo-${tileId}`);
      bar
        .getElementsByClassName("toggleFullScreen")[0]
        .setAttribute("id", `toggleFullScreen-${tileId}`);
      bar
        .getElementsByClassName("requestPayment")[0]
        .setAttribute("id", `requestPayment-${tileId}`);

      bar
        .getElementsByClassName("toggleCloseFullScreen")[0]
        .setAttribute("id", `toggleCloseFullScreen-${tileId}`);
       bar
        .getElementsByClassName("toggleMaximizeScreen")[0]
        .setAttribute("id", `toggleMaximizeScreen-${tileId}`);
      bar
        .getElementsByClassName("togglePresentation")[0]
        .setAttribute("id", `togglePresentation-${tileId}`);
      bar
        .getElementsByClassName("manageConference")[0]
        .setAttribute("id", `manageConference-${tileId}`);

      // if ( bar.getElementsByClassName("outIcon")[0] ){
      //     bar
      //       .getElementsByClassName("outIcon")[0]
      //       .setAttribute("id", `outIcon-${tileId}`);
      // }

      // if ( bar.getElementsByClassName("inIcon")[0] ){
      //   bar
      //     .getElementsByClassName("inIcon")[0]
      //     .setAttribute("data-tileId", `${tileId}`);
      // }

      if ( bar.getElementsByClassName("listAttendees")[0] ){
        bar
          .getElementsByClassName("listAttendees")[0]
          .setAttribute("id", `listAttendees-${tileId}`);
      }

      bar
      .getElementsByClassName("toggleMultiMessage")[0]
        .setAttribute("id", `toggleMultiMessage-${tileId}`);
      document
       .getElementById(`toggleMultiMessage-${tileId}`)
        .addEventListener("click", () => {
          this.toggleMultiMessage(tileId);
        });
      //  bar
      // .getElementsByClassName("toggleSelectiveMute")[0]
      //   .setAttribute("id", `toggleSelectiveMute-${tileId}`);
      // document
      //  .getElementById(`toggleSelectiveMute-${tileId}`)
      //   .addEventListener("click", () => {
      //     this.toggleSelectiveMute(tileId);
      //   });
      bar
        .getElementsByClassName("toggleMuteAll")[0]
        .setAttribute("id", `toggleMuteAll-${tileId}`);
      bar
        .getElementsByClassName("toggleRotateCamera")[0]
        .setAttribute("id", `toggleRotateCamera-${tileId}`);
      bar
        .getElementsByClassName("toggleMuteAllRed")[0]
        .setAttribute("id", `toggleMuteAllRed-${tileId}`);
      bar
        .getElementsByClassName("toggleMuteAudioSlash")[0]
        .setAttribute("id", `toggleMuteAudioSlash-${tileId}`);
      bar
        .getElementsByClassName("toggleMuteAudio")[0]
        .setAttribute("id", `toggleMuteAudio-${tileId}`);
      bar
        .getElementsByClassName("toggleModerator")[0]
        .setAttribute("id", `toggleModerator-${tileId}`);
        bar
        .getElementsByClassName("toggleShareScreen")[0]
        .setAttribute("id", `toggleShareScreen-${tileId}`);
         bar
        .getElementsByClassName("toggleShareScreenSlash")[0]
        .setAttribute("id", `toggleShareScreenSlash-${tileId}`);
         bar
        .getElementsByClassName("toggleShareTT")[0]
        .setAttribute("id", `toggleShareTT-${tileId}`);
        document
        .getElementById(`toggleWaveHandFilled-${tileId}`)
        .addEventListener("click", () => {
          this.toggleWaveHand(tileId);
        });
        document
        .getElementById(`toggleMuteAudioSlash-${tileId}`)
        .addEventListener("click", () => {
          this.toggleMuteAudio(tileId);
        });
        document
        .getElementById(`toggleMuteAudio-${tileId}`)
        .addEventListener("click", () => {
          this.toggleMuteAudio(tileId);
        });
        document
        .getElementById(`toggleWaveHand-${tileId}`)
        .addEventListener("click", () => {
          this.toggleWaveHand(tileId);
        });
        document
        .getElementById(`toggleGiveWord-${tileId}`)
        .addEventListener("click", () => {
          this.toggleGiveWord(tileId);
        });
        document
        .getElementById(`toggleMessage-${tileId}`)
        .addEventListener("click", () => {
          this.toggleMessage(tileId);
        });
        document
        .getElementById(`toggleSendEmail-${tileId}`)
        .addEventListener("click", () => {
          this.toggleSendEmail(tileId);
        });
      document
        .getElementById(`togglePresentation-${tileId}`)
        .addEventListener("click", () => {
          this.togglePresentation(tileId);
        });
      document
        .getElementById(`manageConference-${tileId}`)
        .addEventListener("click", () => {
          this.manageConference(tileId);
        });
        document
        .getElementById(`toggleModerator-${tileId}`)
        .addEventListener("click", () => {
          this.toggleModerator(tileId);
        });
         document
        .getElementById(`toggleMultiMessage-${tileId}`)
        .classList.add("d-none");
        // document
        // .getElementById(`toggleSelectiveMute-${tileId}`)
        // .classList.add("d-none");
        document
        .getElementById(`toggleGiveWord-${tileId}`)
        .classList.add("d-none");
        document
        .getElementById(`toggleModerator-${tileId}`)
        .classList.add("d-none");
      document
        .getElementById(`togglePresentation-${tileId}`)
        .classList.add("d-none");
      document
        .getElementById(`manageConference-${tileId}`)
        .classList.add("d-none");
      if(!isLocal)
        document
          .getElementById(`toggleShareScreen-${tileId}`)
          .classList.add("d-none");
      document
        .getElementById(`toggleShareScreenSlash-${tileId}`)
        .classList.add("d-none");
      document
        .getElementById(`toggleMessage-${tileId}`)
        .classList.add("d-none");
        document
        .getElementById(`toggleSendEmail-${tileId}`)
        .classList.add("d-none");
        document
        .getElementById(`toggleHangUp-${tileId}`)
        .classList.add("d-none");
        document
          .getElementById(`toggleRemoteMic-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleWaveHand-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleWaveHandFilled-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleCloseFullScreen-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMaximizeScreen-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMuteAudioSlash-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMuteAll-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMuteAllRed-${tileId}`)
          .classList.add("d-none");
      if (!isLocal && !isMobile())  {
        // Mimi
        const requestPaymentButton = document.getElementById(`requestPayment-${tileId}`);
        const objParticipant = this.room.getParticipantById(tileId);
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        if ((this.isWaitingRoomUser(personId) || this.isVisitor(personId, true)) && this.room.isModerator()){
          if ( requestPaymentButton ){
            requestPaymentButton.classList.remove("d-none");
          }
        }else{
          requestPaymentButton.classList.add("d-none");
        }
        // END Mimi
        document
          .getElementById(`toggleSendEmail-${tileId}`)
          .classList.add("d-none");
      if (this.currentDesktop !== tileId){
        document
          .getElementById(`toggleMessage-${tileId}`)
          .classList.remove("d-none");
      }
        document
          .getElementById(`toggleVideo-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMic-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleRotateCamera-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`btnDocs-${tileId}`)
          .classList.add("d-none");
          if( isMobile() && document.getElementById(`toggleFullScreen-${tileId}`) ){
          document.getElementById(`toggleFullScreen-${tileId}`).classList.add("d-none");
          }
        // document
        //   .getElementById(`toggleFullScreen-${tileId}`)
        //   .classList.add("d-none");
        document
          .getElementById(`rejectCall-${tileId}`)
          .classList.add("d-none");
        document
          .getElementById(`toggleMuteAudio-${tileId}`)
          .classList.add("d-none");
      }else{
        if( !this.conferenceDocuments || this.conferenceDocuments.length === 0 ){
          document
          .getElementById(`btnDocs-${tileId}`)
          .classList.add("d-none");
        }
        const requestPaymentButton = document.getElementById(`requestPayment-${tileId}`);
        if ( requestPaymentButton ){
          requestPaymentButton.classList.add("d-none");
        }
      }
      // local only show docs if available
      // if( this.conferenceDocuments && this.conferenceDocuments.length > 0){
      //   document
      //   .getElementById(`btnDocs-${tileId}`)
      //   .classList.remove("d-none");
      // }
        document
        .getElementById(`toggleMuteAll-${tileId}`)
        .addEventListener("click", () => {
        this.toggleMuteAll(tileId);
        });
        document
        .getElementById(`toggleRotateCamera-${tileId}`)
        .addEventListener("click", () => {
        this.toggleRotateCamera(tileId);
        });
        document
        .getElementById(`toggleMuteAllRed-${tileId}`)
        .addEventListener("click", () => {
        this.toggleMuteAll(tileId);
        });
        document
        .getElementById(`rejectCall-${tileId}`)
        .addEventListener("click", () => {
          this.rejectCall(tileId);
        });
        document
        .getElementById(`toggleHangUp-${tileId}`)
        .addEventListener("click", () => {
          this.toggleHangUp(tileId);
        });
        document
        .getElementById(`toggleShareScreen-${tileId}`)
        .addEventListener("click", () => {
          this.toggleShareScreen(tileId);
        });
        document
        .getElementById(`toggleRemoteMic-${tileId}`)
        .addEventListener("click", () => {
          this.toggleRemoteMic(tileId);
        });
      document
        .getElementById(`toggleMic-${tileId}`)
        .addEventListener("click", () => {
          this.toggleMic(tileId);
        });
       document
        .getElementById(`btnDocs-${tileId}`)
        .addEventListener("click", () => {
          this.showDocs(tileId);
        });
      document
        .getElementById(`toggleVideo-${tileId}`)
        .addEventListener("click", () => {
          this.toggleVideo(tileId);
        });
      document
        .getElementById(`toggleFullScreen-${tileId}`)
        .addEventListener("click", () => {
          this.switchScreenSize(tileId, 'fullscreen');
        });
      // MIMI
      const requestPaymentButton = document.getElementById(`requestPayment-${tileId}`);
      if ( requestPaymentButton ){
        requestPaymentButton.addEventListener("click", () => {
          if(this.isPayEnabled){
            const objParticipant = this.room.getParticipantById(tileId);
            const userData = objParticipant.getDisplayName();
            const personId = this.getUUIDForDisplayName(userData);
            // this.participantInPayment=tileId;
            // this.uuidInPayment=personId;
            this.setRequestPayment(personId, tileId)
          }else {
            const data = {
              show: true,
              header: this.$t("generics.info"),
              body: this.$t("components.inviteVisitor.payLinkNote"),
            };
            this.setInfoModal(data)
          }
        });
      }
      // END MIMI
      document
        .getElementById(`toggleCloseFullScreen-${tileId}`)
        .addEventListener("click", () => {
          this.switchScreenSize(tileId, 'normal');
        });

       document
        .getElementById(`toggleMaximizeScreen-${tileId}`)
        .addEventListener("click", () => {
          this.switchScreenSize(tileId, 'maximize');
        });

    },
    isUserModerator(participantId) {
      if (!this.room) return;
      const participant = this.room.getParticipantById(participantId);
      if (participant) return participant.isModerator();
      if (participantId === this.room.myUserId()) {
        return this.room.isModerator();
      }
      return false;
    },
    conference() {
      const initOptions = {
        disableAudioLevels: false,
        disableSimulcast: false,
      };
      // console.log('isMobile '+isMobile());
      // console.log('hasMobileParticipant '+this.hasMobileParticipant);
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
      JitsiMeetJS.init(initOptions);
      const connection = new JitsiMeetJS.JitsiConnection(
        null,
        null,
        this.jvbOptions
      );
      this.connection = connection;
      connection.connect();
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        () => {
          this.onConnectionSuccess(this.getCallUUID);
        }
      );
    },
    async onConnectionSuccess(callUUID) {
      console.log("xmpp connected", callUUID);
      const confOptions = {
        p2p: {
          enabled: false,
        },
      };
      const room = this.connection.initJitsiConference(callUUID, confOptions);
      room.setReceiverVideoConstraint(1080);
      // console.log('Setting Display Name  ',
      //           this.getAvatarForUuid(this.ownUUID),
      //           this.getTitelForUuid(this.ownUUID),
      //           this.getVisitorFunctionForUuid(this.ownUUID),
      //           this.getNameForUuid(this.ownUUID)
      //        )
      console.log('e2ee support',room.isE2EESupported());
      var userName = '';
      // console.log('uuids to hide '+this.usersToHideName);
      // console.log('myuuid '+this.ownUUID);

      if(! this.usersToHideName.includes(this.ownUUID))
      {
        userName =
                (this.getTitelForUuid(this.ownUUID) == '' || this.getTitelForUuid(this.ownUUID) == 'unknown' ) ?
                (this.getVisitorFunctionForUuid(this.ownUUID) !== '') ? this.getNameForUuid(this.ownUUID) + ' ' + this.getVisitorFunctionForUuid(this.ownUUID):
                this.getNameForUuid(this.ownUUID) : (this.getTitelForUuid(this.ownUUID) + ' ' + this.getNameForUuid(this.ownUUID));
      }
      room.setDisplayName(
          this.ownUUID == "recorder"
            ? "recorder"
            : "regular_"+this.ownUUID+`_${JSON.stringify([
                this.getAvatarForUuid(this.ownUUID),
                userName
              ])}`
      );
      room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onConferenceJoined
      );
      room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
      room.on(JitsiMeetJS.events.conference.KICKED, this.onKicked);
      room.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, this.onDominantSpeakerChanged);
      room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onTrackRemove);
      room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
      room.on(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.onTrackAudioLevelChanged);
      room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,this.localStatsUpdated);
      room.on(
        JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
        this.onUserRoleChanged
      );
      const pmc = this.onPresenterModeCommand;
      const whc = this.onWaveHandCommand;
      const gwc = this.onGiveWordCommand;
      const ssc = this.onStreamStateCommand;
      const pmu = this.onParticipantsMutedCommand;
      const pdf = this.onParticipantDeaf;
      const scd = this.ownUUID == "recorder" ? () => {} : this.setCallDuration;
      const rjc = this.rejectCall;
      const smm = this.onSendMultiUserMessage;
      const uun = this.onUpdateUserName;
      const uml = this.activateJoinButton;

      room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, function(id,msg,ts) {
        // console.log('cmd message :'+msg);
        const message = JSON.parse(msg);
        if(message.cmd == 'cmdPresenterMode') {
          pmc({value:message.value,initiator:message.initiator})
        }
        else if(message.cmd == 'cmdStreamState') {
          ssc({value:message.value, wallpaperSrc:message.wallpaperSrc})
        }
        else if(message.cmd == 'cmdParticipantsMuted') {
          pmu(message.value)
        }
        else if(message.cmd == 'cmdParticipantDeaf') {
          pdf(message.participant,message.value)
        }
        else if(message.cmd == 'cmdCurrentTime') {
          scd(callUUID, message.value)
        }
        else if(message.cmd == 'cmdEndCall') {
          rjc();
        }
        else if(message.cmd == 'cmdWaveHand') {
          whc(message.user,message.value);
        }
        else if(message.cmd == 'cmdGiveWord') {
          gwc(message.user);
        }
        else if (message.cmd === 'cmdSendMultiUserMessage') {
          smm(message.value);
        }
        else if (message.cmd === 'cmdUpdateUserName') {
          uun(message.value, message.toWho);
        }
        else if (message.cmd === 'cmdUpdateManageList') {
          // console.log('here ! ', message.value)
          uml(message.value);
        }
      });

      this.room = room;
      room.join();
    },
    sortKeyBuilder(local, desktop, displayName, uuid) {
      return (
        "1" + "1" + (desktop ? "0" : "1") + "1" +
        "1" + (local ? "0" : "1") +
        "1" +
        displayName.toLowerCase() +
        uuid
      );
    },
    onSendMultiUserMessage(val){
      this.setNewMessageFromCall(val)
    },
    onUpdateUserName(val, id){
      const inputTopbar = document.getElementById('inputTopbar-' + id);
      const callerName = document.getElementById('callerName-' + id);
      inputTopbar.setAttribute('value', val);
      callerName.innerHTML = val;
    },
    onPresenterModeCommand(val) {
      if (val && val.value && val.value == 1) {
        // console.log('onPresenterModeCommand 1 initiated by '+val.initiator);
        this.presenterModeInitiatedBy = val.initiator;
        const iconBarUserElem = document.getElementById("iconBar-" + this.room.myUserId());
        if (iconBarUserElem) {
          const presentationButton = iconBarUserElem.getElementsByClassName("togglePresentation")[0];
          if (presentationButton) {
            presentationButton.getElementsByClassName('ttpresent')[0].innerHTML=this.$t("components.bridgeCallTooltips.endPresentation")
            if((this.presenterModeInitiatedBy != this.room.myUserId()) && !this.room.isModerator()) {
              presentationButton.classList.add("d-none");
            } else {
              presentationButton.classList.remove("d-none");
            }
          }
        }
        if(! this.room.isModerator()){
          if(document.getElementById('toggleWaveHand-'+this.room.myUserId()))
            document.getElementById('toggleWaveHand-'+this.room.myUserId()).classList.remove('d-none');
        }
        if(this.presenterMode == 1){
          this.switchGrid(0);
        }
        this.switchGrid(1);
        this.updateButtonBars();
      } else {
        // console.log('onPresenterModeCommand 0');
        this.presenterModeInitiatedBy = null;
        this.wordGivenTo = null;
        if(this.room.isModerator()){
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdGiveWord",user:null}));
        }
        const iconBarUserElem = document.getElementById("iconBar-" + this.room.myUserId());
        if (iconBarUserElem) {
          const presentationButton = iconBarUserElem.getElementsByClassName("togglePresentation")[0];
          if (presentationButton) {
            presentationButton.getElementsByClassName('ttpresent')[0].innerHTML = this.$t("components.bridgeCallTooltips.presentation")
            presentationButton.classList.remove("d-none");
            for(const element of document.getElementsByClassName("toggleWaveHand")) {
              element.classList.add('d-none');
            }
            for(const element of document.getElementsByClassName("toggleWaveHandFilled")) {
              element.classList.add('d-none');
            }
          }
        }
        this.switchGrid(0);
        this.updateButtonBars();
      }
    },
    localStatsUpdated(statsObject){
      // console.log('fame Rate ', statsObject.framerate);
      for(const pid of Object.keys(statsObject.framerate)){
        for (const rate of Object.keys(statsObject.framerate[pid])){
          const valueRate = statsObject.framerate[pid][rate];
          const quality = document.getElementById( `quality-${pid}`)
          if ( valueRate  < 4) {
            if(quality) quality.classList.remove('d-none');
          } else {
            if(quality) quality.classList.add('d-none');
          }
        }
      }
    },
    onRemoteTrack(track) {
      if (track.isLocal()) return; // ignore local tracks
      const participantId = track.getParticipantId();
      const trackType = track.getType();
      console.log(
        "remote " + (track.isP2P ? "p2p " : "") + (track.videoType ? `${track.videoType} ` : "") + trackType + " track added for " + participantId
      );
      if (
        this.desktopRoom &&
        this.desktopRoom.myUserId() &&
        this.desktopRoom.myUserId() == participantId
      )
        return;
      const parent = document.getElementById(participantId);
      if (track.getType() === "video") {
        let vidElement = document.getElementById("localVideo").cloneNode(true);
        vidElement.setAttribute("id", "remoteVideo-" + participantId);
        vidElement.classList.remove("d-none");
        parent.appendChild(vidElement);
        vidElement.classList.add("camera");
        vidElement.muted = true;
        vidElement.setAttribute("autoplay", 1);
        vidElement.addEventListener("click", () => {
          if ( !isMobile() ){
            this.toggleFullscreenRoutine(participantId)
          }
        });
        track.attach(vidElement);
        const image = this.getUserImage(participantId);
        if (image) {
          image.classList.add("d-none");
        }
      } else if (track.getType() == "audio") {
        const audioElement = document.createElement("audio");
        audioElement.setAttribute("id", "remoteAudio-" + participantId);
        if(this.participantsMuted[participantId] == true ||this.audioOutputMuted == true) {
          audioElement.muted = true;
          // console.log('start muted');
        }
        audioElement.setAttribute("autoplay", 1);
        parent.appendChild(audioElement);
        track.attach(audioElement);
        track.addEventListener(
          TRACK_AUDIO_LEVEL_CHANGED,
          function (audioLevel) {}
        );
      }
    },
    onLocalTracks(tracks) {
      // console.log('onLocalTracks event');
      //  console.log('Room documents ', this.conferenceDocuments);
      if(! this.room) return;
      const participantId = this.room.myUserId();
      tracks.forEach(async (track) => {
        if (track.getType() == "video") {
          console.log('local video track created');
          let vidElement = document
            .getElementById("localVideo")
            .cloneNode(true);
          vidElement.classList.remove("d-none");
          let parent = document.getElementById(participantId);
          parent.appendChild(vidElement);
          vidElement.classList.add("camera");
          vidElement.muted = true;
          vidElement.setAttribute("autoplay", 1);
          track.attach(vidElement);
          this.room.addTrack(track).then(() => {
            this.lockVideoToggle = false;
          });
        } else if (track.getType() == "audio") {
          console.log('local audio track created');
          let audioElement = document.createElement("audio");
          let parent = document.getElementById(participantId);
          parent.appendChild(audioElement);
          audioElement.muted = true;
          audioElement.setAttribute("autoplay", 1);
          audioElement.setAttribute("id", "localAudio");

          if (this.isConferenceCall && !this.callerInitiator){
            const buttonMic = document.getElementById(`toggleMic-${participantId}`);
            const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
            const micIconSlash = buttonMic.getElementsByClassName("microphone-slash")[0];
            const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${participantId}`);
            micIcon.classList.add("d-none");
            micIconSlash.classList.remove("d-none");
            micIconSlashOverlay.classList.remove("d-none");
            this.micMute = true;
            await track.mute();
          }

          track.attach(audioElement);
          this.room.addTrack(track).then(() => {
            this.lockAudioToggle = false;
          });
        }
      });
    },
    async onConferenceJoined() {
      //this.room.toggleE2EE(true);
      const myId = this.room.myUserId();
      if (this.ownUUID == "recorder") {
        const heartbeat = () => {
          if (!this.room) return;
          let count = 0;
          for (const participant of this.room.getParticipants()) {
            if (participant.getDisplayName() != "recorder") count++;
          }
          if (count != 0 && window.stream_keep_alive) {
            window.stream_keep_alive();
            setTimeout(heartbeat, 3000);
          }
        };
        setTimeout(heartbeat, 3000);
      }
      if (this.ownUUID != "recorder") {
        this.currentCallingState = [];
        for(const uuid of this.amICalling){
          const sortKey = this.sortKeyBuilder(
            false,
            false,
            this.getNameForUuid(uuid),
            uuid
          );
          const aName = this.getNameForUuid(uuid);
          const title = this.getTitelForUuid(uuid);
          const visitorFunction = this.getVisitorFunctionForUuid(uuid);
          const fullNameDisplay = (title == '' || title == 'unknown')
            ? aName + ' ' + `${visitorFunction || ''}`
            : (title + ' ' + aName);
          this.gridAdd(uuid, fullNameDisplay, sortKey);
          this.addAvatarToPlaceholder(uuid);
          this.addTextToPlaceholder(uuid);
          this.currentCallingState.push(uuid);
        }
        const sortKey = this.sortKeyBuilder(
          true,
          false,
          this.getNameForUuid(this.ownUUID),
          this.ownUUID
        );
        const aName = this.getNameForUuid(this.ownUUID);
        const title = this.getTitelForUuid(this.ownUUID);
        const visitorFunction = this.getVisitorFunctionForUuid(this.ownUUID);
        const fullNameDisplay = (title == '' || title == 'unknown')
          ? aName + ' ' + `${visitorFunction || ''}`
          : (title + ' ' + aName);
        this.gridAdd(myId, fullNameDisplay, sortKey);
        this.addBar(myId, true);
        this.activateLeaveButton();
        this.addCompanyLogo(myId, true)
        this.addMicrophoneSlashOverlay(myId, true)
        this.addUserImage(myId, this.ownUUID);
        if(this.isAudioOnly == true) {
          const devices = await navigator.mediaDevices
            .enumerateDevices("audio")
            .then((devices) => {
              // console.log('devices:'+JSON.stringify(devices,null,2));
              let audioInputDevice = devices.filter(
                (d) => d.kind === "audioinput" && d.deviceId === "communications"
              )[0];
              // should try for "communications", then "default" then 1st element existing
              if (!audioInputDevice) {
                audioInputDevice = devices.filter(
                  (d) => d.kind === "audioinput" && d.deviceId === "default"
                )[0];
              }
              if (!audioInputDevice) {
                audioInputDevice = devices.filter(
                  (d) => d.kind === "audioinput"
                )[0];
              }
              return {
                audioInputDevice: audioInputDevice.groupId,
              };
            });
          if (this.audioDeviceId) {
            devices.audioInputDevice = this.audioDeviceId;
          }
          // console.log('mic selected:'+devices.audioInputDevice);
          if (devices.audioInputDevice) {
            JitsiMeetJS.createLocalTracks({
              devices: ["audio"],
              micDeviceId: devices.audioInputDevice,
            })
              .then((tracks) => {
                this.onLocalTracks(tracks);
              })
              .catch((error) => {
                throw error;
              });
          }
          this.videDisabled = true;
          const buttonVideo = document.getElementById(`toggleVideo-${myId}`);
          if ( buttonVideo ){
            const videoIcon = buttonVideo.getElementsByClassName("video")[0];
            const videoIconSlash = buttonVideo.getElementsByClassName(
              "video-slash"
            )[0];
            if ( videoIcon ){
              videoIcon.classList.add("d-none");
            }

            if ( videoIconSlash ){
              videoIconSlash.classList.remove("d-none");
            }
            const image = this.getUserImage(myId);
            if (image) {
              image.classList.remove("d-none");
            }
            this.lockVideoToggle = false;
          }

        }else{
          const devices = await navigator.mediaDevices
            .enumerateDevices("audio", "video")
            .then((devices) => {
              // console.log('devices:'+JSON.stringify(devices,null,2));
              let audioInputDevice = devices.filter(
                (d) => d.kind === "audioinput" && d.deviceId === "communications"
              )[0];
              // should try for "communications", then "default" then 1st element existing
              if (!audioInputDevice) {
                audioInputDevice = devices.filter(
                  (d) => d.kind === "audioinput" && d.deviceId === "default"
                )[0];
              }
              if (!audioInputDevice) {
                audioInputDevice = devices.filter(
                  (d) => d.kind === "audioinput"
                )[0];
              }
              const videoInputDevice = devices.filter(
                (d) => d.kind === "videoinput"
              )[0];
              return {
                audioInputDevice: audioInputDevice.groupId,
                videoInputDevice: videoInputDevice.groupId,
              };
            });
          if (this.videoDeviceId) {
            devices.videoInputDevice = this.videoDeviceId;
          }
          if (this.audioDeviceId) {
            devices.audioInputDevice = this.audioDeviceId;
          }
          // console.log('mic selected:'+devices.audioInputDevice);
          // console.log('cam selected:'+devices.videoInputDevice);
          if (devices.audioInputDevice && devices.videoInputDevice) {
            JitsiMeetJS.createLocalTracks({
              devices: ["audio", "video"],
              micDeviceId: devices.audioInputDevice,
              cameraDeviceId: devices.videoInputDevice,
            })
              .then((tracks) => {
                this.onLocalTracks(tracks);
              })
              .catch((error) => {
                throw error;
              });
          }else{
            JitsiMeetJS.createLocalTracks({
              devices: ["audio", "video"],
            })
              .then((tracks) => {
                this.onLocalTracks(tracks);
              })
              .catch((error) => {
                throw error;
              });
          }

          if(this.room.isModerator() && !this.hasPrivilege(this.ownUUID)) {
            for(const participant of this.room.getParticipants()) {
              const userData = participant.getDisplayName();
              const personId = this.getUUIDForDisplayName(userData);
              if(this.hasPrivilege(personId)) {
                this.dropModOnNewMod = true;
                this.room.giveMod(participant.getId());
              }
            }
          }
        }
        const userIcon = document.getElementById('userIconTile-'+ myId);
        const guestLabel = document.getElementById('guestLabelTile-'+ myId);
        if(!this.room.isModerator() && ! isMobile()){
          if (this.hasPrivilege(this.ownUUID) == false){
            // user is visitor
            userIcon.classList.add('d-none');
            guestLabel.classList.remove('d-none');
          } else {
            // user is normal
            userIcon.classList.remove('d-none');
            guestLabel.classList.add('d-none');
          }
        }
      }
    },
    onTrackAudioLevelChanged(participantId, audioLevel) {
      // toggleMic-
      const myMic = document.getElementById('toggleMic-'+participantId);
      const remoteMic = document.getElementById('toggleRemoteMic-'+participantId);
      if (myMic) {
        const level = myMic.getElementsByClassName('progressBarLevelBridge')[0];
        level.style.width = audioLevel * 100 + "%";
      }
      if (remoteMic) {
        const levelRemote = remoteMic.getElementsByClassName('progressBarLevelBridge')[0];
        levelRemote.style.width = audioLevel * 100 + "%";
      }
    },
    onDominantSpeakerChanged(participantId) {
      if(this.activeUsers < 3 ) return;
      //console.log('dominant speaker:'+participantId);
      for(const element of document.getElementsByClassName("tile")) {
        element.parentNode.classList.remove('dominantSpeaker');
        element.parentNode.parentNode.classList.remove('dominantSpeaker');
      }
      if(document.getElementById(participantId).parentNode.classList.contains('grm')){
        document.getElementById(participantId).parentNode.classList.add('dominantSpeaker');
      }else{
        document.getElementById(participantId).parentNode.parentNode.classList.add('dominantSpeaker');
      }
    },
    onUserJoined(participantId) {
      console.log("user joined:", participantId);
      this.updateAttendanceList(this.ownUUID,'online');
      const myId = this.room.myUserId();
      const objParticipant = this.room.getParticipantById(participantId);
      let  userData = objParticipant.getDisplayName();

      if (
        this.desktopRoom &&
        this.desktopRoom.myUserId() &&
        this.desktopRoom.myUserId() == participantId
      ){
        this.currentDesktop = participantId;
        return;
      }

      // console.log('jitsi mod state: '+this.room.isModerator());
      // console.log('jitsi role variable: '+this.room.getRole());

      if (this.room.isModerator()){
        if( userData.split("_")[0] != "desktop") {
          // console.log('inform presenterMode');
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:this.presenterMode,initiator:this.presenterModeInitiatedBy}));
        }
      }

      if(this.ownUUID != "recorder" && userData == "recorder") {
        this.recorderParticipantId = participantId;
        if(this.room.isModerator()) {
          const buttonToToggle = document.getElementById('togglePlay-'+this.room.myUserId());
          const playIcon = buttonToToggle.getElementsByClassName("fa-play")[0];
          const pauseIcon = buttonToToggle.getElementsByClassName("fa-pause")[0];
           const spinnerIcon = buttonToToggle.getElementsByClassName("fa-spinner")[0]
          playIcon.classList.add('d-none');
          pauseIcon.classList.remove('d-none');
          spinnerIcon.classList.add('d-none')
        }
        if(this.streamState == 'stopped' || this.streamState == 'cleared') {
          const container = document.getElementById('contentWrapper');
          container.classList.add('recorderOn');
          document.getElementById('pausedButtonWrapper').classList.add('d-none');
          document.getElementById('recButtonWrapper').classList.remove('d-none');
          this.streamState = 'streaming';
        }
        return;
      }

      const participantUUID = this.getUUIDForDisplayName(userData);
      if (this.confModerators && this.confModerators.length > 0) {
        if (this.confModerators.indexOf(participantUUID) != -1) {
        this.room.giveMod(participantId);
        }
      }

      if (this.assistantsInitiatorList && this.assistantsInitiatorList.length > 0 && this.amIInitiator === this.ownUUID) {
        if ( this.assistantsInitiatorList.indexOf(participantUUID) != -1) {
          // give assistant moderator
          this.room.giveMod(participantId);
        }
      }
      if (this.room.isModerator()){
        if(this.streamState == 'paused') {
          // console.log('inform stream state '+this.streamState);
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdStreamState",value:this.streamState}));
        }
        // console.log('inform muted '+this.streamState);
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantsMuted",value:this.participantsMuted}));
      }

      this.currentDesktop = userData.split("_")[0] == "desktop" ? participantId : this.currentDesktop;
      const sortKey = this.sortKeyBuilder(
        false,
        userData.split("_")[0] == "desktop" ? true : false,
        this.extractNameFromUserData(userData),
        this.getUUIDForDisplayName(userData)
      );

      if( userData.split("_")[0] == "desktop") {
        for(const element of document.getElementsByClassName("toggleShareScreen")) {
          element.classList.add('d-none');
        }
      }

      const isModerator = this.isUserModerator(participantId);

      if(this.toBeRemovedFromCallState[this.getUUIDForDisplayName(userData)])
      {
        delete this.toBeRemovedFromCallState[this.getUUIDForDisplayName(userData)];

        this.gridReplace(
          this.getUUIDForDisplayName(userData),
          participantId,
          this.extractNameFromUserData(userData),
          sortKey
        );
        if(! isMobile()) if(isModerator){
          document.getElementById('moderatorIconInfo-' + participantId).classList.remove('d-none');
          document.getElementById('userIconInfo-' + participantId).classList.add('d-none');
        } else {
          document.getElementById('moderatorIconInfo-' + participantId).classList.add('d-none');
          document.getElementById('userIconInfo-' + participantId).classList.remove('d-none');
        }
      } else{
        // let fromPayment = false;
        // if(this.uuidInPayment == this.getUUIDForDisplayName(userData)){
        //   console.log('returns from payment');
        //   this.gridRemove(this.participantInPayment);
        //   this.setUserInPayment(null, null);
        //   fromPayment = true;
        //   // this.uuidInPayment=null;
        //   // this.participantInPayment=null;
        // }
        this.gridAdd(
          participantId,
          this.extractNameFromUserData(userData),
          sortKey
        );

        // if ( fromPayment ){
        //   this.setAttach(participantId)
        // }

        if(! isMobile()) if(isModerator){
          // name must be 84px left
          document.getElementById('moderatorIconInfo-' + participantId).classList.remove('d-none');
          document.getElementById('userIconInfo-' + participantId).classList.add('d-none');
        } else {
          // name must be 30px left
          document.getElementById('moderatorIconInfo-' + participantId).classList.add('d-none');
          document.getElementById('userIconInfo-' + participantId).classList.remove('d-none');
        }
      }
      this.addUserImage(participantId, userData);
      const image = this.getUserImage(participantId);
      if (image) {
        image.classList.remove("d-none");
      }
      if(this.ownUUID != "recorder"){
        // if (this.currentDesktop == participantId){
        //   this.addDesktopBar(participantId)
        // } else {
          this.addBar(participantId, false);
       // }
        this.activateLeaveButton();
        this.addCompanyLogo(participantId, false);
        this.addMicrophoneSlashOverlay(participantId, false)
        const moderatorButton = document.getElementById('toggleModerator-' + participantId)
        const requestWordButton = document.getElementById('toggleResquestWord-' + participantId)
        const hangUpButton = document.getElementById('toggleHangUp-' + participantId)
        const remoteMicbutton = document.getElementById('toggleRemoteMic-' + participantId)
        const toggleSendEmail = document.getElementById('toggleSendEmail-' + participantId)
        const toggleRotateCamera = document.getElementById('toggleRotateCamera-' + participantId)
        const requestPayment = document.getElementById('requestPayment-' + participantId)
        // const toggleMessage = document.getElementById('toggleMessage-' + participantId)
        // const toggleFullScreen = document.getElementById('toggleFullScreen-' + participantId)

        if ( toggleSendEmail  && this.isUserModerator(myId)) {
          toggleSendEmail.classList.remove('d-none');
        }
        if (requestWordButton && !this.isUserModerator(myId) && this.presenterMode == 1){
          requestWordButton.classList.remove('d-none');
        }
        if (moderatorButton && this.isUserModerator(myId)) {
          moderatorButton.classList.remove('d-none');
        }
        if (hangUpButton && this.isUserModerator(myId)){
          hangUpButton.classList.remove('d-none');
        }
        if (remoteMicbutton && this.isUserModerator(myId)){
          remoteMicbutton.classList.remove('d-none');
        }
        const userIcon = document.getElementById('userIconTile-'+ participantId);
        const guestLabel = document.getElementById('guestLabelTile-'+ participantId);
        if(!isMobile()) if (this.hasPrivilege(this.getUUIDForDisplayName(userData)) == false){
          // user is visitor
          userIcon.classList.add('d-none');
          guestLabel.classList.remove('d-none');
        } else {
          // user is normal
          userIcon.classList.remove('d-none');
          guestLabel.classList.add('d-none');
        }

        if (this.currentDesktop == participantId){
          if(requestWordButton) requestWordButton.classList.add('d-none');
          if(moderatorButton) moderatorButton.classList.add('d-none');
          if(hangUpButton) hangUpButton.classList.add('d-none');
          if(remoteMicbutton) remoteMicbutton.classList.add('d-none');
          if(toggleSendEmail) toggleSendEmail.classList.add('d-none');
          if(toggleRotateCamera) toggleRotateCamera.classList.add('d-none');
          if(requestPayment) requestPayment.classList.add('d-none');
          // if(toggleMessage) toggleMessage.classList.add('d-none');
          // if(toggleFullScreen) toggleFullScreen.classList.remove('d-none')
        }
      }
      this.updateButtonBars();
    },
    async onUserLeft(participantId) {
      console.log("user left:", participantId);
      if(!this.room) return;
      for(const element of document.getElementsByClassName("tile")) {
        element.parentNode.classList.remove('dominantSpeaker');
        element.parentNode.parentNode.classList.remove('dominantSpeaker');
      }
      if(participantId == this.maximizedTile){
        this.maximizedTile = null;
        this.switchGrid(this.presenterMode);
        if (this.IsFullScreen()){
          this.exitFullScreen()
        }
      }

      if(this.currentDesktop == participantId) {
        this.currentDesktop = null;
        if(document.getElementById("toggleShareScreen-"+this.room.myUserId()))
          document.getElementById("toggleShareScreen-"+this.room.myUserId()).classList.remove('d-none');
      }
      if(this.room.isModerator() && (this.presenterMode == 1)&&(participantId == this.presenterModeInitiatedBy )) {
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:0,initiator:null}));
        this.onPresenterModeCommand({value:0,initiator:null});
        const presentationButton = document
           .getElementById("iconBar-" + this.room.myUserId())
          .getElementsByClassName("togglePresentation")[0];
        presentationButton.classList.remove("d-none");
      }
      if(this.recorderParticipantId == participantId) {
        if(this.room.isModerator()) {
          const buttonToToggle = document.getElementById('togglePlay-'+this.room.myUserId());
          const playIcon = buttonToToggle.getElementsByClassName("fa-play")[0];
          const pauseIcon = buttonToToggle.getElementsByClassName("fa-pause")[0];
          const spinnerIcon = buttonToToggle.getElementsByClassName("fa-spinner")[0]
          playIcon.classList.remove('d-none');
          pauseIcon.classList.add('d-none');
          spinnerIcon.classList.add('d-none');
        }
        document.getElementById('recButtonWrapper').classList.add('d-none');
        document.getElementById('pausedButtonWrapper').classList.add('d-none');
        const container = document.getElementById('contentWrapper')
        container.classList.remove('recorderOn');
        this.streamState = 'stopped';
        return;
      }

      // if(this.participantInPayment != participantId){
      // Last man standing
      if (this.activeUsers && this.activeUsers < 3 ){
        console.log('hangup B');
        await this.hasCallEnded(this.getCallUUID);
      }
      var nonGuestInCall = false;

      for(const participant of this.room.getParticipants()) {
        const userData = participant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        if(this.hasPrivilege(personId)) {
          nonGuestInCall = true;
        }
      }
      if(this.hasPrivilege(this.ownUUID)) nonGuestInCall = true;

      this.gridRemove(participantId);
      if ((!nonGuestInCall) ||(this.activeUsers < 2)) { // desktop user counts as one so keep in mind when rejecting...
        if(! this.disconnectInProgress) {
          console.log('hangup A');
          await this.rejectCall();
        }
      }else{
        if ( this.room ){
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdUpdateManageList", value:participantId}));
        }
        this.updateButtonBars();
      }
      // }
    },
    onTrackRemove(track) {
      if (track.isLocal()) return; // ignore local tracks
      const participantId = track.getParticipantId();
      const tile = document.getElementById(participantId);
      if (!tile) return;
      if (track.getType() == "audio") {
        const audioElement = tile.getElementsByTagName("audio")[0];
        if (audioElement) {
          // console.log('remove Audio Element in Track remove');
          track.detach(audioElement);
          audioElement.remove();
        }
      }
      if (track.getType() == "video") {
        const videoElement = tile.getElementsByTagName("video")[0];
        if (videoElement) {
          track.detach(videoElement);
          videoElement.remove();
          // console.log('remove Video Element in Track remove');
          const image = this.getUserImage(participantId);
          if (image) {
            image.classList.remove("d-none");
          }
        } else {
          console.warn("no video element found to remove");
        }
      }
    },
    onUserRoleChanged(participantId, role) {
      // console.log('User Role Change:'+participantId+' to '+role);
      if(!participantId) return;
      if(!this.room) return;
      const isRemote = this.room.myUserId() != participantId;
      let userData;
      if (!isRemote) {
        userData = this.ownUUID;
      } else {
        const objParticipant = this.room.getParticipantById(participantId);
        userData = objParticipant.getDisplayName();
      }

      if(role == "moderator" && this.room.isModerator() && this.dropModOnNewMod == true) {
        this.room.takeMod();
        this.dropModOnNewMod=false;
      }

      const parent = document.getElementById(participantId);
      if (!parent) return;

      const userName = this.extractNameFromUserData(userData);
      const inputValue = parent.getElementsByClassName('inputTopbar')[0].value;
      if ( inputValue != '' && inputValue !== userName ){
        parent.getElementsByClassName("callerName")[0].innerHTML = inputValue;
      }else{
        parent.getElementsByClassName("callerName")[0].innerHTML = userName;
      }

      if(!isMobile() && role == "moderator"){
        document.getElementById('moderatorIconInfo-' + participantId).classList.remove('d-none');
        document.getElementById('userIconInfo-' + participantId).classList.add('d-none');
      } else {
        if ( document.getElementById('moderatorIconInfo-' + participantId) ){
          document.getElementById('moderatorIconInfo-' + participantId).classList.add('d-none');
        }
        if ( document.getElementById('userIconInfo-' + participantId) ){
          document.getElementById('userIconInfo-' + participantId).classList.remove('d-none');
        }
      }

      if ( role == "moderator" && !isRemote ){
        this.showRequestPaymentButton()
      }
      // const moderatorButton = document.getElementById('toggleModerator-' + participantId)
      if(!isRemote){
        const requestButton = document.getElementById('toggleWaveHand-' + participantId);
        if ( requestButton ){
          if(!this.room.isModerator() && this.presenterMode == 1 ) {
            requestButton.classList.remove('d-none')
          } else {
            requestButton.classList.add('d-none')
          }
        }
        let multiMessageButton, presentationButton, manageButton;
        if ( document.getElementById("iconBar-" + participantId) ){
          manageButton =  document
            .getElementById("iconBar-" + participantId)
            .getElementsByClassName("manageConference")[0];
          presentationButton = document
          .getElementById("iconBar-" + participantId)
          .getElementsByClassName("togglePresentation")[0];

          multiMessageButton = document
          .getElementById("iconBar-" + participantId)
          .getElementsByClassName("toggleMultiMessage")[0];
        }

        // const btnSelectiveMute = document
        //  .getElementById("iconBar-" + participantId)
        //  .getElementsByClassName("toggleSelectiveMute")[0];
        const recorderBar = document.getElementById('recBar-' + participantId);

        if (role == "moderator") {
          if (multiMessageButton) {
            multiMessageButton.classList.remove('d-none');
          }
          // console.log('attendees ',  this.conferenceAttendees, manageButton)
          if (manageButton && this.conferenceAttendees && this.conferenceAttendees.length > 0) { // && this.callerInitiator){
            manageButton.classList.remove('d-none');
          }
          // if ( btnSelectiveMute ){
          //    btnSelectiveMute.classList.remove('d-none')
          // }
          // const buttonMic = document.getElementById('toggleMic-'+this.room.myUserId());
          // recorderBar.classList.remove("d-none");
          const buttonToToggle = document.getElementById('togglePlay-'+this.room.myUserId());
          const playIcon = buttonToToggle ? buttonToToggle.getElementsByClassName("fa-play")[0] : null;
          const pauseIcon = buttonToToggle ? buttonToToggle.getElementsByClassName("fa-pause")[0] : null;
          const spinnerIcon = buttonToToggle ? buttonToToggle.getElementsByClassName("fa-spinner")[0] : null;
          if ( spinnerIcon ){
            spinnerIcon.classList.add('d-none')
          }
          if (this.streamState == 'paused' || this.streamState == 'stopped' || this.streamState == 'cleared'){
            if ( playIcon ) playIcon.classList.remove('d-none');
            if ( pauseIcon ) pauseIcon.classList.add('d-none');
          } else{
            if ( playIcon ) playIcon.classList.add('d-none');
            if ( pauseIcon ) pauseIcon.classList.remove('d-none');
          }

          if ( presentationButton ){
            if(this.presenterMode) {
              if(this.presenterModeInitiatedBy != this.room.myUserId())
              {
                presentationButton.classList.add("d-none");
              }
              else
              {
                presentationButton.classList.remove("d-none");
              }
            } else {
              presentationButton.classList.remove("d-none");
            }
          }
        } else {
          if ( recorderBar ){
            recorderBar.classList.add("d-none");
          }
          if ( presentationButton ){
            presentationButton.classList.add("d-none");
          }

          if ( multiMessageButton ){
            if (!isMobile()) {
              multiMessageButton.classList.add('d-none');
            } else {
              multiMessageButton.classList.remove('d-none');
            }
          }
        }
      }
      const participants = this.room.getParticipants();
      for(const participant of participants) {
        const participantId = participant.getId();
        const toggleSendEmail = document.getElementById('toggleSendEmail-' + participantId)
        if (toggleSendEmail)
          if(this.room.isModerator()) {
            toggleSendEmail.classList.remove('d-none');
          } else {
            toggleSendEmail.classList.add('d-none');
          }
      }
      for(const participant of participants) {
        const id = participant.getId();
        const modIcon = document.getElementById('toggleModerator-'+ id)
        const hangUpButton = document.getElementById('toggleHangUp-' + id)
        const remoteMicbutton = document.getElementById('toggleRemoteMic-' + id)
        if (this.isUserModerator(id) || !this.room.isModerator() ){
          if ( modIcon ){
            modIcon.classList.add('d-none');
          }
          if (remoteMicbutton){
            remoteMicbutton.classList.add('d-none');
          }
        } else {
          if ( modIcon && this.currentDesktop != id){
            modIcon.classList.remove('d-none');
          }
          if ( remoteMicbutton && this.currentDesktop != id){
            remoteMicbutton.classList.remove('d-none');
            const micIcon = remoteMicbutton.getElementsByClassName("fa-microphone")[0];
            const micIconSlash = remoteMicbutton.getElementsByClassName("microphone-slash")[0];
            const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${id}`);
            if(this.participantsMuted[id] == true){
              if ( micIcon ) micIcon.classList.add("d-none");
              if ( micIconSlash ) micIconSlash.classList.remove("d-none");
              if ( micIconSlashOverlay ) micIconSlashOverlay.classList.remove("d-none");
            }else{
              if ( micIcon ) micIcon.classList.remove("d-none");
              if ( micIconSlash ) micIconSlash.classList.add("d-none");
              if ( micIconSlashOverlay ) micIconSlashOverlay.classList.add("d-none");
            }
          }
        }
        if (!this.room.isModerator() ){

          if (hangUpButton){
            hangUpButton.classList.add('d-none');
          }
        } else {

          if ( hangUpButton && this.currentDesktop != id){
            hangUpButton.classList.remove('d-none');
          }
        }
      }
      if(this.presenterMode == 1){
        // console.log('switch grid pm to 0 & to 1');
        this.switchGrid(0);
        this.switchGrid(1);
      }
      // condition to send to lineabar de current moderator
      this.getModerator(this.room.isModerator())
      // if (this.isUserModerator(participantId)){
      //   let userUUID;
      //   if (!isRemote) {
      //     userUUID = this.ownUUID;
      //   } else {
      //     userUUID = this.getUUIDForDisplayName(userData)
      //   }
      //   this.getModerator(userUUID);
      // }
      this.updateButtonBars();

      if(participantId == this.room.myUserId() && role == 'moderator' && !this.hasPrivilege(participantId)) {
        var onlyGuests = true;
        for(const participant of this.room.getParticipants()) {
          const userData = participant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          if(this.hasPrivilege(personId)) {
            onlyGuests = false;
          }
        }
        if(onlyGuests){
          this.rejectCall();
        } else {
          for(const participant of this.room.getParticipants()) {
            const userData = participant.getDisplayName();
            const personId = this.getUUIDForDisplayName(userData);
            if(this.hasPrivilege(personId)) {
              this.dropModOnNewMod = true;
              this.room.giveMod(participant.getId());
            }
          }
        }
      }
    },
    resetStreamButtons(participantId) {
      const buttonToToggle = document.getElementById(`togglePlay-${participantId}`);
      const playIcon = buttonToToggle.getElementsByClassName("fa-play")[0];
      const pauseIcon = buttonToToggle.getElementsByClassName("fa-pause")[0];
      const spinnerIcon = buttonToToggle.getElementsByClassName("fa-spinner")[0]
      spinnerIcon.classList.add('d-none')
      playIcon.classList.remove('d-none')
      pauseIcon.classList.add('d-none')
      this.streamIsPaused = false;
      this.streamIsPlaying = false;
    },
    async onKicked(_p) {
      // console.log('kicked');
      const toRemove = this.room.getLocalTracks();
      for (const track of toRemove) {
        if (track.getType() === "video") {
          const elementVideo = document.getElementById("localVideo");
          if (elementVideo){
            track.detach(elementVideo);
            elementVideo.remove();
          }
        }
        if (track.getType() === "audio") {
          const elementAudio = document.getElementById("localAudio");
          if (elementAudio){
            track.detach(elementAudio);
            elementAudio.remove();
          }
        }
        track.dispose();
      }
      this.room = null;
      if (this.ownUUID == "recorder") {
        console.log('window.stream_stop');
        if (window.stream_stop) window.stream_stop();
      }
      if (this.rejectCallCb) {
        this.rejectCallCb();
      }
    },
    async rejectCall() {
      this.disconnectInProgress = true;
      if (this.desktopRoom) {
        const toRemove = this.desktopRoom.getLocalTracks();
        for (const track of toRemove) {
          await this.desktopRoom.removeTrack(track);
          track.dispose();
        }
        this.gridRemove(this.desktopRoom.myUserId());
        if (this.desktopRoom.isJoined()) await this.desktopRoom.leave();
        this.desktopRoom = null;
      }
      if (this.desktopConnection) {
        if (
          this.desktopConnection.xmpp.connection &&
          !this.desktopConnection.xmpp.disconnectInProgress
        ) {
          await this.desktopConnection.disconnect();
        }else{
          console.warn('desktop connection already dropped in reject call');
        }
        this.desktopConnection = null;
      }
      if(this.room){
        const toRemove = this.room.getLocalTracks();
        for (const track of toRemove) {
          if(track.containers[0]) track.detach(track.containers[0]);
          await track.setEffect(undefined);
          if(track.conference && track.conference.participants.length > 0) await track.conference.removeTrack(track);
          await track.dispose();
        }
        if (this.room.isJoined()) await this.room.leave();
        this.room = null;
      }
      if (
        this.connection.xmpp.connection &&
        !this.connection.xmpp.disconnectInProgress
      ) {
        await this.connection.disconnect();
      }else{
      }
      this.connection = null;
      if (this.ownUUID == "recorder") {
        console.log('window.stream_stop');
        if (window.stream_stop) window.stream_stop();
      }
      if (this.rejectCallCb) {
        this.rejectCallCb();
      }
    },
    toggleMultiMessage(participantId){
      if(!isMobile()) {
        this.setSendMessageInCallModal()
      } else {
        const participants = this.room.getParticipants();
        for(const objParticipant of participants) {
          if(objParticipant.getId != this.room.myUserId()) {
            const userData = objParticipant.getDisplayName();
            const personId = this.getUUIDForDisplayName(userData);
            this.showSendMessage(personId, 'regularMsg');
          }
        }
      }
    },
    // toggleSelectiveMute(participantId){
    //   console.log('Selective muting for all but ', participantId)
    // },
    toggleMuteAll(tileId){
      var allmuted = true;
      var count = 0;
      for(const participant of this.room.getParticipants()){
        if(participant.getDisplayName() == "recorder") continue;
        if(participant.getDisplayName().split('_')[0] == 'desktop') continue;
        if(!participant.isModerator()){
          if(this.participantsMuted[participant.getId()] != true) allmuted=false;
          count++;
        }
      }
      const muteAll = document.getElementById(`toggleMuteAll-${this.room.myUserId()}`);
      const muteAllRed = document.getElementById(`toggleMuteAllRed-${this.room.myUserId()}`);
      if(!allmuted || count == 0){
        muteAll.classList.add("d-none");
        muteAllRed.classList.remove("d-none");
        for(const participant of this.room.getParticipants()){
          if(participant.getDisplayName() == "recorder") continue;
          if(participant.getDisplayName().split('_')[0] == 'desktop') continue;
          // console.log(participant.getDisplayName());
          const participantId = participant.getId();
          if(!participant.isModerator()){
            const buttonMic = document.getElementById(`toggleRemoteMic-${participantId}`);
            const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
            const micIconSlash = buttonMic.getElementsByClassName("microphone-slash")[0];
            const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${participantId}`);

            this.participantsMuted[participantId] = true;
            micIcon.classList.add("d-none");
            micIconSlash.classList.remove("d-none");
            micIconSlashOverlay.classList.remove("d-none");
            for (const audio of Array.from(document.querySelectorAll('audio'))){
              if(audio.getAttribute('id') == 'remoteAudio-'+participantId)
              audio.muted = true
            }
          }
        }
      }else{
        muteAll.classList.remove("d-none");
        muteAllRed.classList.add("d-none");
        for(const participant of this.room.getParticipants()){
          if(participant.getDisplayName() == "recorder") continue;
          if(participant.getDisplayName().split('_')[0] == 'desktop') continue;
          const participantId = participant.getId();
          if(!participant.isModerator()){
            const buttonMic = document.getElementById(`toggleRemoteMic-${participantId}`);
            const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
            const micIconSlash = buttonMic.getElementsByClassName("microphone-slash")[0];
            const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${participantId}`);

            delete this.participantsMuted[participantId];
            micIcon.classList.remove("d-none");
            micIconSlash.classList.add("d-none");
            micIconSlashOverlay.classList.add("d-none");
            for (const audio of Array.from(document.querySelectorAll('audio'))){
              if(audio.getAttribute('id') == 'remoteAudio-'+participantId)
              audio.muted = false
            }
          }
        }
      }
      this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantsMuted",value:this.participantsMuted}));
    },
    toggleRemoteMic(participantId){
      const buttonMic = document.getElementById(`toggleRemoteMic-${participantId}`);
      const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
      const micIconSlash = buttonMic.getElementsByClassName("microphone-slash")[0];
      const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${participantId}`);

      if(this.participantsMuted[participantId] == true){
        delete this.participantsMuted[participantId];
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantsMuted",value:this.participantsMuted}));
        micIcon.classList.remove("d-none");
        micIconSlash.classList.add("d-none");
        micIconSlashOverlay.classList.add("d-none");
        for (const audio of Array.from(document.querySelectorAll('audio'))){
          if(audio.getAttribute('id') == 'remoteAudio-'+participantId)
            audio.muted = false
        }
      }else{
        this.participantsMuted[participantId] = true;
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantsMuted",value:this.participantsMuted}));
        micIcon.classList.add("d-none");
        micIconSlash.classList.remove("d-none");
        for (const audio of Array.from(document.querySelectorAll('audio'))){
          if(audio.getAttribute('id') == 'remoteAudio-'+participantId)
            audio.muted = true
        }
      }
    },
    onParticipantsMutedCommand(val){
      this.participantsMuted = {};
      for(const pid of Object.keys(val)){
        this.participantsMuted[pid]=true;
      }

      if ( this.ownUUID != "recorder" && ! isMobile()) {
        const buttonMic = document.getElementById('toggleMic-'+this.room.myUserId());
        const micIconSlashOverlay = document.getElementById('microphoneSlashOverlay-'+this.room.myUserId());
        if(val[this.room.myUserId()] == true){
          const buttonMic = document.getElementById('toggleMic-'+this.room.myUserId());
          buttonMic.getElementsByClassName("microphone-slash")[0].classList.remove("d-none");
          buttonMic.getElementsByClassName("fa-microphone")[0].classList.add("d-none");
          buttonMic.getElementsByClassName("ttmike")[0].textContent = this.$t("components.callsContent.micRemoteMute");
          micIconSlashOverlay.classList.remove("d-none")
        }else{
          buttonMic.getElementsByClassName("ttmike")[0].textContent = this.$t("components.callsContent.mic");
          for (const track of this.room.getLocalTracks()) {
            if (track.getType() === "audio") {
              if(track.isMuted()){
                buttonMic.getElementsByClassName("microphone-slash")[0].classList.remove("d-none");
                buttonMic.getElementsByClassName("fa-microphone")[0].classList.add("d-none");
                micIconSlashOverlay.classList.remove("d-none")
              }else{
                buttonMic.getElementsByClassName("microphone-slash")[0].classList.add("d-none");
                buttonMic.getElementsByClassName("fa-microphone")[0].classList.remove("d-none");
                micIconSlashOverlay.classList.add("d-none")
              }
            }
          }

        }
      }

      for (const audio of Array.from(document.querySelectorAll('audio'))){
        if(audio.getAttribute('id') && audio.getAttribute('id').startsWith('remoteAudio-')){
          const participantId = audio.getAttribute('id').split('-')[1];
          if ( this.ownUUID != "recorder" ) {
            const buttonMic = document.getElementById(`toggleRemoteMic-${participantId}`);
            const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${participantId}`);
            const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
            const micIconSlash = buttonMic.getElementsByClassName("microphone-slash")[0];
            if(val[participantId] == true){
              if(this.room.isModerator()) {
                micIcon.classList.add("d-none");
                micIconSlash.classList.remove("d-none");
                micIconSlashOverlay.classList.remove("d-none");
              }
            }else{
              if(this.room.isModerator()) {
                micIcon.classList.remove("d-none");
                micIconSlash.classList.add("d-none");
                micIconSlashOverlay.classList.add("d-none");
              }
            }
          }
          if(val[participantId] == true){
            // console.log('mute remote: '+participantId);
            audio.muted = true
          }else{
            audio.muted = false
          }
        }
      }

      this.updateButtonBars();
    },
    onParticipantDeaf(tileId,val){
      // console.log('updatre deaflabel for '+tileId+' to '+val);
      if(tileId == this.room.myUserId()) return;
      const deafLabel = document.getElementById(`deafen-${tileId}`);
      if(deafLabel)
        if(val) {
          // console.log(document.getElementById(`callerName-${tileId}`).textContent);
          deafLabel.textContent = this.$t("components.bridgeCallTooltips.deafenTT",[document.getElementById(`callerName-${tileId}`).textContent]);
          deafLabel.classList.remove("d-none");
        }else{
          deafLabel.classList.add("d-none");
        }
    },
    toggleMuteAudio(tileId) {
      // console.log('Toggle audio output');
      // variable to show/hide the volume slash icon
      this.audioOutputMuted = !this.audioOutputMuted;
      const audioElements = document.getElementsByTagName("audio");
      const muteButton = document.getElementById(`toggleMuteAudio-${tileId}`);
      const muteButtonSlash = document.getElementById(`toggleMuteAudioSlash-${tileId}`);
      this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantDeaf",participant:tileId,value:this.audioOutputMuted}));
      if(this.audioOutputMuted) {
        muteButton.classList.add("d-none");
        muteButtonSlash.classList.remove("d-none");
      }else{
        muteButton.classList.remove("d-none");
        muteButtonSlash.classList.add("d-none");
      }
      for(var audioElement of audioElements) {
        if(audioElement.id != 'localAudio') {
          audioElement.muted = this.audioOutputMuted;
        }
      }
    },
    async showDocs(tileId){
      // display doc list
      const documentsList = document.getElementById(`listDocuments-${tileId}`);
      if ( documentsList.classList.contains('d-none') ){
        documentsList.classList.remove('d-none');
      }else{
        documentsList.classList.add('d-none');
      }
    },
    async toggleMic(tileId) {
      if (this.lockAudioToggle) return;
      if (this.participantsMuted[tileId] == true){
        if(this.room.isModerator()){
          delete this.participantsMuted[this.room.myUserId()];
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdParticipantsMuted",value:this.participantsMuted}));
          if(! this.micMute) return;
        }else{
          return;
        }
      }
      this.lockAudioToggle = true;
      this.micMute = !this.micMute; // change to your variable
      const buttonMic = document.getElementById(`toggleMic-${tileId}`);
      const micIconSlashOverlay = document.getElementById(`microphoneSlashOverlay-${tileId}`);
      const micIcon = buttonMic.getElementsByClassName("fa-microphone")[0];
      const micIconSlash = buttonMic.getElementsByClassName(
        "microphone-slash"
      )[0];
      if (this.micMute) {
        micIcon.classList.add("d-none");
        micIconSlash.classList.remove("d-none");
        micIconSlashOverlay.classList.remove("d-none");
        for (const track of this.room.getLocalTracks()) {
          if (track.getType() === "audio") {
            await track.mute();
            this.lockAudioToggle = false;
          }
        }
      } else {
        micIcon.classList.remove("d-none");
        micIconSlash.classList.add("d-none");
        micIconSlashOverlay.classList.add("d-none");
        for (const track of this.room.getLocalTracks()) {
          if (track.getType() === "audio") {
            await track.unmute();
            this.lockAudioToggle = false;
          }
        }
      }
    },
    async toggleRotateCamera() {
        if( this.mobileCamera == 'user'){
          this.mobileCamera = 'environment'
        }else{
          this.mobileCamera = 'user'
        }

        const elementVideo = document.getElementById("localVideo");
        for (const track of this.room.getLocalTracks()) {
          if (track.getType() === "video") {
            await track.detach(elementVideo);
            await this.room.removeTrack(track);
            track.dispose();
          }
        }

        // console.log('new facingMode:'+this.mobileCamera);
        JitsiMeetJS.createLocalTracks({
          devices: ["video"],
          facingMode: { exact: this.mobileCamera }
        })
          .then((tracks) => {
            this.onLocalTracks(tracks);
          })
          .catch((error) => {
            throw error;
          });

    },
    async toggleVideo(tileId) {
      if (this.lockVideoToggle) return;
      this.lockVideoToggle = true;
      // const localKey = this.sortKeyBuilder(
      //   true,
      //   false,
      //   this.getNameForUuid(this.ownUUID),
      //   this.ownUUID
      // );
      this.videDisabled = !this.videDisabled; // change to your variable
      const buttonVideo = document.getElementById(`toggleVideo-${tileId}`);
      const videoIcon = buttonVideo.getElementsByClassName("video")[0];
      const videoIconSlash = buttonVideo.getElementsByClassName(
        "video-slash"
      )[0];
      // const userImage = document.getElementById('userImage-'+ id)
      if (this.videDisabled) {
        videoIcon.classList.add("d-none");
        videoIconSlash.classList.remove("d-none");
        for (const track of this.room.getLocalTracks()) {
          if (track.getType() === "video") {
            const elementVideo = document.getElementById("localVideo");
            track.detach(elementVideo);
            elementVideo.remove();
            const image = this.getUserImage(tileId);
            if (image) {
              image.classList.remove("d-none");
            }
            this.room.removeTrack(track).then(() => {
              videoIconSlash.classList.remove("hidden");
              track.dispose();
              this.lockVideoToggle = false;
            });
          }
        }
      } else {
        videoIcon.classList.remove("d-none");
        videoIconSlash.classList.add("d-none");
        const image = this.getUserImage(tileId);
        if (image) {
          image.classList.add("d-none");
        }
        const devices = await navigator.mediaDevices
          .enumerateDevices("video")
          .then((devices) => {
            const videoInputDevice = devices.filter(
              (d) => d.kind === "videoinput"
            )[0];
            return {
              videoInputDevice: videoInputDevice.groupId,
            };
          });
        if (this.videoDeviceId) {
          devices.videoInputDevice = this.videoDeviceId;
        }
        JitsiMeetJS.createLocalTracks({
          devices: ["video"],
          cameraDeviceId: devices.videoInputDevice,
        })
          .then((tracks) => {
            this.onLocalTracks(tracks);
          })
          .catch((error) => {
            throw error;
          });
      }
    },
    toggleFullscreenRoutine(participantId){
      const FullScrennType =  ['fullscreen', 'maximize', 'normal'];
      this.switchScreenSize(participantId, FullScrennType[this.positionOfFullScreenArray]);
      this.positionOfFullScreenArray += 1
      if(this.positionOfFullScreenArray>2) this.positionOfFullScreenArray = 0
    },
    switchScreenSize(participantId, mode = 'normal' ) {
      // console.log(participantId, 'participantId', mode, 'mode')
      const fullScreenButton = document.getElementById('toggleFullScreen-' + participantId);
      const normalModeScreenButton = document.getElementById('toggleCloseFullScreen-' + participantId);
      const maximazeScreenButton = document.getElementById('toggleMaximizeScreen-' + participantId);

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to fullscreen
      // +++++++++++++++++++++++++++++++++++++++++++++++
      if (mode == 'fullscreen') {
        if(!this.IsFullScreen()){
          let elem;
          elem = document.getElementById("confWrapper");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
          }
        }
        if(participantId != this.room.myUserId()){
           fullScreenButton.classList.add('d-none');
           normalModeScreenButton.classList.add('d-none');
           maximazeScreenButton.classList.remove('d-none')
        }else{
          fullScreenButton.classList.add('d-none');
          normalModeScreenButton.classList.remove('d-none');
          maximazeScreenButton.classList.add('d-none')
        }
        for(const element of document.getElementsByClassName("toggleMessage")){
          if(!element.classList.contains('d-none')){
            element.classList.add('hiddenInFullScreen')
          }
        }
      }
      if(!isMobile() && mode == 'fullscreen') {
        // hidden send message icon in fullscreen
        for(const element of document.getElementsByClassName("toggleMultiMessage")){
          if(!element.classList.contains('d-none')){
            element.classList.add('hiddenInFullScreen')
          }
        }
      }

      if(participantId != this.room.myUserId()){
        if(mode == 'normal'){
          this.maximizedTile = null;
          this.switchGrid(this.presenterMode);
        }else{
          this.maximizedTile = participantId;
          this.switchGrid(this.presenterMode);
        }
      }

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to maximize mode
      // +++++++++++++++++++++++++++++++++++++++++++++++

      if ( mode == 'maximize'){
        if (this.IsFullScreen()){
          this.exitFullScreen()
        }
        if(fullScreenButton) fullScreenButton.classList.add('d-none');
        if(normalModeScreenButton) normalModeScreenButton.classList.remove('d-none');
        if(maximazeScreenButton) maximazeScreenButton.classList.add('d-none')
        // put send message icon if not fullscreen
        for(const element of document.getElementsByClassName("hiddenInFullScreen")){
          element.classList.remove('hiddenInFullScreen');
        }
      }

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to normal mode
      // +++++++++++++++++++++++++++++++++++++++++++++++
      if (mode == 'normal') {
        if (this.IsFullScreen()) {
          this.exitFullScreen();
        }
        for(const element of document.getElementsByClassName("toggleFullScreen")){
          element.classList.remove('d-none');
        }
        for(const element of document.getElementsByClassName("toggleCloseFullScreen")){
          element.classList.add('d-none');
        }
        for(const element of document.getElementsByClassName("toggleMaximizeScreen")){
          element.classList.add('d-none');
        }
        // // put send message icon if not fullscreen
          for(const element of document.getElementsByClassName("hiddenInFullScreen")){
          element.classList.remove('hiddenInFullScreen');
        }
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(() => {});
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },

    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    async toggleShareScreen (tileId){
      // console.log('toggleShareScreen', tileId)
      this.shareScreenMode = (this.shareScreenMode == 0 ? 1 : 0);
      const toggleShareScreenSlash = document.getElementById('toggleShareScreenSlash-' + tileId);
      const shareTTElement = document.getElementById('toggleShareTT-' + tileId);
      if (toggleShareScreenSlash) {
        if (this.shareScreenMode == 1){
          toggleShareScreenSlash.classList.remove('d-none');
          if(shareTTElement){
            shareTTElement.innerHTML = this.$t('components.callsContent.endShareScreen');
          }
        } else {
          toggleShareScreenSlash.classList.add('d-none')
           if(shareTTElement){
            shareTTElement.innerHTML = this.$t('components.callsContent.shareScreen');
          }
        }
      }
      if (this.shareScreenMode == 1){
        if(this.wordGivenTo != null) {
          this.wordGivenTo = null;
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdGiveWord",user:null}));
        }
        // create connection
        const connection = new JitsiMeetJS.JitsiConnection(
          null,
          null,
          this.jvbOptions
        );
        this.desktopConnection = connection;
        connection.connect();
        connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
          () => {
            this.onDesktopConnectionSuccess(this.getCallUUID);
          }
        );
      } else {
        // remove connection
        this.bridgeCallScreenShareState(false);
        if (this.desktopRoom) {
          const toRemove = this.desktopRoom.getLocalTracks();
          for (const track of toRemove) {
            await this.desktopRoom.removeTrack(track);
            track.dispose();
          }
          this.gridRemove(this.desktopRoom.myUserId());
          if (this.desktopRoom.isJoined()) await this.desktopRoom.leave();
          this.desktopRoom = null;
        }
        if (this.desktopConnection) {
          if (
            this.desktopConnection.xmpp.connection &&
            !this.desktopConnection.xmpp.disconnectInProgress
          ) {
            await this.desktopConnection.disconnect();
            // console.log('desktop connection dropped');
          }else{
            console.warn('desktop connection already dropped');
          }
          this.desktopConnection = null;
        }
        if (this.implicitPresenterMode==true) {
          this.implicitPresenterMode=false;
          this.switchGrid(0);
          // console.log('send presenterMode '+this.presenterMode);
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:this.presenterMode,initiator:null}));
          this.onPresenterModeCommand({value:0});
        }
      }
    },
    toggleModerator(tileId){
      this.room.giveMod(tileId);
      //if (this.shareScreenMode == 1){
      //  this.toggleShareScreen(this.room.myUserId())
      //}
      //this.dropModOnNewMod=true;
    },
    async manageConference(tileId){
      // console.log('Manage Conferences')
      const listAttendees = document.getElementById(`listAttendees-${tileId}`);
      if ( listAttendees.classList.contains('d-none') ){
        listAttendees.classList.remove('d-none');
      }else{
        listAttendees.classList.add('d-none');
      }
    },
    async togglePresentation(tileId) {
      // console.log('togglePresentation');
      if (this.presenterMode == 1 && this.shareScreenMode == 1){
        await this.toggleShareScreen(tileId);
      }
      if(this.presenterMode == 0) {
        this.presenterModeInitiatedBy = this.room.myUserId();
      }else{
        this.presenterModeInitiatedBy = null;
      }
      this.switchGrid(this.presenterMode == 0 ? 1 : 0);
      // console.log('send presenterMode '+this.presenterMode);
      this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:this.presenterMode,initiator:this.room.myUserId()}));
      this.onPresenterModeCommand({value:this.presenterMode,initiator:this.room.myUserId()});
    },
    async onDesktopConnectionSuccess(callUUID) {
      const confOptions = {
        p2p: {
          enabled: false,
        },
      };
      const room = this.desktopConnection.initJitsiConference(
        callUUID,
        confOptions
      );
      room.setDisplayName(
        "desktop_" +
          this.ownUUID +
          (this.ownUUID == "recorder"
            ? ""
            : `_${JSON.stringify([
                this.getAvatarForUuid(this.ownUUID),
                (this.getTitelForUuid(this.ownUUID) == '' || this.getTitelForUuid(this.ownUUID) == 'unknown' ) ?
                (this.getVisitorFunctionForUuid(this.ownUUID) !== '') ? this.getNameForUuid(this.ownUUID) + ' ' + this.getVisitorFunctionForUuid(this.ownUUID):
                this.getNameForUuid(this.ownUUID) :
                (this.getTitelForUuid(this.ownUUID) + ' ' + this.getNameForUuid(this.ownUUID))
              ])}`)
      );
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onDesktopConferenceJoined
      );
      this.desktopRoom = room;
      room.join();
    },
    stopPresenterMode(){
        if (this.presenterMode == 1 && this.implicitPresenterMode==true) {
          this.implicitPresenterMode=false;
          this.switchGrid(0);
          // console.log('send presenterMode '+this.presenterMode);
          this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:this.presenterMode,initiator:null}));
          this.onPresenterModeCommand({value:0});
        }
    },
    async onDesktopConferenceJoined() {
      const myId = this.desktopRoom.myUserId();
      // console.log("onDesktopConferenceJoined , participantId:", myId);
      if (this.presenterMode == 0) {
        this.switchGrid(1);
        // console.log('send presenterMode '+this.presenterMode);
        this.implicitPresenterMode=true,
        this.room.sendTextMessage(JSON.stringify({cmd:"cmdPresenterMode",value:this.presenterMode,initiator:this.room.myUserId()}));
      }
      this.updateButtonBars();
      const tooglefunc = this.toggleShareScreen;
      const tooglefunc2 = this.stopPresenterMode;
      const myRealId = this.room.myUserId();
     // if (this.ownUUID != "recorder") {
        JitsiMeetJS.createLocalTracks({
          devices: ["desktop"],
        })
          .then((tracks) => {
            const sortKey = this.sortKeyBuilder(
              true,
              true,
              this.getNameForUuid(this.ownUUID),
              this.ownUUID
            );
            this.gridAdd(
              myId,
              ((this.getTitelForUuid(this.ownUUID) == '' || this.getTitelForUuid(this.ownUUID) == 'unknown' ) ? this.getNameForUuid(this.ownUUID) : (this.getTitelForUuid(this.ownUUID) + ' ' + this.getNameForUuid(this.ownUUID))) + ' - ' + this.getTextForSharing() , //" - Screen Share",
              sortKey
            );
            // this.addDesktopBar(myId) add regular bar this.addBar(participantId, false);
            this.addBar(myId, false);
            this.onLocalDesktopTracks(tracks);
          })
          .catch(function(error){
            tooglefunc(myRealId);
            tooglefunc2();
          });
     // }
    },
    onLocalDesktopTracks(tracks) {
      const participantId = this.desktopRoom.myUserId();
      // console.log("on local desktop tracks, participantId:", participantId);

      tracks.forEach((track) => {
        track.on(
          JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
          this.onDesktopTrackStopped
        );
        let vidElement = document.getElementById("localVideo").cloneNode(true);
        vidElement.classList.remove("d-none");
        let parent = document.getElementById(participantId);
        parent.appendChild(vidElement);
        vidElement.classList.add("camera");
        vidElement.muted = true;
        vidElement.setAttribute("autoplay", 1);
        track.attach(vidElement);
        this.desktopRoom.addTrack(track).then(() => {});
        this.bridgeCallScreenShareState(true);
      });
    },
    onDesktopTrackStopped() {
      if (this.shareScreenMode == 1){
        this.toggleShareScreen(this.room.myUserId())
      }
    },
    getAvatarForDisplayName(userUUID) {
      try {
        if (userUUID.includes("_")) {
          const avatar = JSON.parse(
            userUUID.replace(/^[^_]+_/, "").replace(/^[^_]+_/, "") || "[]"
          )[0];
          return avatar;
        }
      } catch (err) {
        console.warn("getAvatarForDisplayName", userUUID, err.stack);
      }
      return this.getAvatarForUuid(userUUID.split("_")[0]);
    },
    addAvatarToPlaceholder(uuid) {
      const avatar = this.getAvatarForUuid(uuid);
      const tileElement = document.getElementById(uuid);
      const imgDiv = document.getElementById("localImage").cloneNode(true);
      imgDiv.setAttribute("id", 'userImage-'+uuid);
      imgDiv.classList.remove("d-none");
      imgDiv.classList.add("incomingPersonAvatar");
      const img = imgDiv.getElementsByClassName("userImageBridge")[0];
      img.setAttribute("src", avatar);
      tileElement.appendChild(imgDiv);
    },
    addTextToPlaceholder(uuid) {
      const imgDiv = document.getElementById('userImage-'+uuid)
      const tileElement = document.getElementById(uuid);
      const generalDiv = document.createElement('div');
      const hangUpButton = document.getElementsByClassName('rejectCall')[0].cloneNode(true)
      hangUpButton.setAttribute("id", "hangUpCalling-"+uuid)
      hangUpButton.addEventListener("click", () => {
        this.hangUpCalling(uuid);
        hangUpButton.disabled = true;
        hangUpButton.classList.add('d-none');
      });
      generalDiv.setAttribute("class", "callingBar");
      const divElement = document.createElement('div');
      const divWave = document.createElement('div');
      divWave.setAttribute("id", 'waveCallingBridge');
      // const userName = document.createElement('div');
      divElement.setAttribute("class", "callingElements");
      // userName.setAttribute("class", 'callingTextBridge');
      // const aName = this.getNameForUuid(uuid);
      // const title = this.getTitelForUuid(uuid);
      // const visitorFunction = this.getVisitorFunctionForUuid(uuid);
      // userName.innerHTML = (title == '' || title == 'unknown')
      //   ? aName + ' ' + `${visitorFunction || ''}`
      //   : (title + ' ' + aName);
      // divWave.appendChild(userName)
      for (let i = 0; i < 3; i++) {
        const divDots = document.createElement('div');
        divDots.setAttribute("class", "dot");
        divWave.appendChild(divDots);
      }

      // divElement.appendChild(userName);
      imgDiv.appendChild(divWave);
      divElement.appendChild(hangUpButton);
      generalDiv.appendChild(divElement);
      tileElement.appendChild(generalDiv);
    },
    fixModeratorName(participantId) {
      const userName = document.getElementById('callerName-' + participantId);
         if ( userName ){
            userName.classList.remove('nameNormal');
            userName.classList.add('nameModerator');
         }
    },
    extractNameFromUserData(userUUID) {
      try {
        if (userUUID.includes("_")) {
          return JSON.parse(
            userUUID.replace(/^[^_]+_/, "").replace(/^[^_]+_/, "") || "[]"
          )[1];
        }
      } catch (err) {
        console.warn("extractNameFromUserData", userUUID, err.stack);
      }
      const aName = this.getNameForUuid(userUUID.split("_")[0]);
      const title = this.getTitelForUuid(userUUID.split("_")[0]);
      const visitorFunction = this.getVisitorFunctionForUuid(userUUID.split("_")[0]);
      return (title == '' || title == 'unknown')
        ? aName + ' ' + `${visitorFunction || ''}`
        : (title + ' ' + aName);
    },
    getUUIDForDisplayName(userUUID) {
      return userUUID.split("_")[1];
    },
    onKeyDown(event) {
      if (event.type === "keydown" && event.keyCode === 17) {
        this.ctrlPressed = true;
      }
    },
    async onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      )
        {
          clearTimeout(this.spaceShortcutCallAcceptTimeout);
          this.spaceShortcutCallAcceptTimeout = null;
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
            this.rejectCall();
          }
        }
        else if (event.type === "keyup" && event.keyCode === 32) {
          this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
            this.spaceShortcutCallAcceptTimeout = null;
          }, 300);
        } else if (event.keyCode === 13 && !this.ctrlPressed) {
          // enter keycode
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
            const myId = this.room.myUserId();
            await this.togglePresentation(myId);
          }
        }
        else if (event.type === "keyup" && event.keyCode === 17) {
          this.ctrlPressed = false;
        }
      // else if (event.type === "keyup" && event.keyCode === 13) {
      //   if (this.ctrlPressed) {
      //     console.log("click ctrPress");
      //     if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
      //       return this.lessTime();
      //     }
      //   }else {
      //     if (Object.keys(store.state.remoteStreams).length > 0) {
      //       if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
      //         this.addMoreTime();
      //       }
      //     }
      //   }
      // } else if (event.type === "keyup" && event.keyCode === 17) {
      //   this.ctrlPressed = false;
      // }
    },
    updateButtonBars(){
      if(! this.room) return;
      if(this.ownUUID == "recorder") return;
      var onlymods = true;
      for(const participant of this.room.getParticipants()){
        if(!participant.isModerator()) onlymods = false;
        if(participant.getDisplayName() == "recorder") continue;
        if(participant.getDisplayName().split('_')[0] == 'desktop') continue;
        this.updateButtonBar(participant.getId(),participant.isModerator());
      }
      if(this.room.myUserId()) this.updateButtonBar(this.room.myUserId(),this.room.isModerator());

      if(this.room.isModerator()){
        var allmuted = true;
        var count = 0;
        for(const participant of this.room.getParticipants()){
          if(participant.getDisplayName() == "recorder") continue;
          if(participant.getDisplayName().split('_')[0] == 'desktop') continue;
          if(!participant.isModerator()){
            if(this.participantsMuted[participant.getId()] != true) allmuted=false;
            count++;
          }
        }
        const screenShare = document.getElementById(`toggleShareScreen-${this.room.myUserId()}`);
        const presentation = document.getElementById(`togglePresentation-${this.room.myUserId()}`);
        const muteAll = document.getElementById(`toggleMuteAll-${this.room.myUserId()}`);
        const muteAllRed = document.getElementById(`toggleMuteAllRed-${this.room.myUserId()}`);
        if(this.hasMobileParticipant) {
            if(screenShare) {
              screenShare.classList.add("d-none");
            }
            if(presentation) {
              presentation.classList.add("d-none");
              }
            if(muteAll) {
              muteAll.classList.add("d-none");
              }
            if(muteAllRed) {
              muteAllRed.classList.add("d-none");
            }
        }else if(onlymods){
            if(muteAll){
              muteAll.classList.add("d-none");
            }
            if(muteAllRed){
              muteAllRed.classList.add("d-none");
            }
        }else if(muteAll && muteAllRed)
          if(!allmuted || count == 0){
            if(muteAll) {
              muteAll.classList.remove("d-none");
            }
            if(muteAllRed){
              muteAllRed.classList.add("d-none");
            }
          }else{
            if(muteAll){
              muteAll.classList.add("d-none");
            }
            if(muteAllRed){
              muteAllRed.classList.remove("d-none");
            }
          }
      }
    },
    updateButtonBar(participantId,isMod){
      // console.log('update buttonbar for '+participantId);
      if (isMod){
        this.fixModeratorName(participantId);
      }
      const giveWordButton = document.getElementById('toggleGiveWord-' + participantId)
      if(giveWordButton)
        if(
          ( (isMod && this.presenterModeInitiatedBy != participantId) || (!isMod) ||(this.wordGivenTo!=participantId && this.wordGivenTo!=null && participantId == this.room.myUserId())) &&
          this.room.isModerator() &&
          (this.wordGivenTo==null || this.wordGivenTo!=participantId) &&
          this.presenterMode == 1 &&
          this.currentDesktop == null
        ){
          giveWordButton.classList.remove('d-none');
        }else{
          giveWordButton.classList.add('d-none');
        }

      const fullScreen = document.getElementById('toggleFullScreen-' + participantId);
      if(fullScreen && isMobile()) {
          fullScreen.classList.add('d-none');
      }

      const rotate = document.getElementById('toggleRotateCamera-' + participantId);
      if(rotate && !isMobile()) {
          rotate.classList.add('d-none');
      }
      const multiMessage = document.getElementById(`toggleMultiMessage-${participantId}`);
      if(multiMessage && isMobile()) {
          multiMessage.classList.remove('d-none');
      }

      const muteAudio = document.getElementById('toggleMuteAudio-' + participantId);
      if(muteAudio && isMobile()) {
          muteAudio.classList.add('d-none');
      }

      const presentation = document.getElementById(`togglePresentation-${participantId}`);
      if(presentation && isMobile()) {
          presentation.classList.add('d-none');
      }

      const remoteMicButton = document.getElementById('toggleRemoteMic-' + participantId)
      if(remoteMicButton &&this.hasMobileParticipant) {
          remoteMicButton.classList.add('d-none');
      }
      const moderatorButton = document.getElementById('toggleModerator-' + participantId)
      if(moderatorButton &&this.hasMobileParticipant) {
          moderatorButton.classList.add('d-none');
      }

      const muteAllButton = document.getElementById('toggleMuteAll-' + participantId)
      if(muteAllButton){
        if(this.hasMobileParticipant) {
          muteAllButton.classList.add('d-none');
        } else if(
          (participantId == this.room.myUserId()) &&
          this.room.isModerator()
        ){
          muteAllButton.classList.remove('d-none');
        }else{
          muteAllButton.classList.add('d-none');
        }
      }

      if(participantId != this.room.myUserId()) {
        const objParticipant = this.room.getParticipantById(participantId);
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        if(!this.hasPrivilege(personId)) {
          moderatorButton.classList.add('d-none');
        }
      }
    },
   getTextForSharing(){
     return this.$t('components.callsContent.sharedScreen');
   }
  },
  computed: {
    isScreenShared(){
      return this.shareScreenMode == 1
    },
    userHasPrivilege(){
      return this.hasPrivilege(this.ownUUID)
    },
    userOneCall(){
      return this.userIsOneCall(this.ownUUID)
    },
    callerInitiator() {
      if (this.amIInitiator && this.amIInitiator === this.ownUUID) {
        // this.callInitiator = this.amIInitiator;
        return true;
      }
      return false;
    },
  },
};
</script>
<style  lang="scss">

.muteAllButon {
  width:20px;
  height:20px;
  color:#fff;
}
.microphone-slash {
  width:25px;
  height:25px;
  color:#fff;
}
.currency{
  height:20px;
}
.video {
  width:36px;
  height:36px;
  color:#fff;
}
.video-slash {
  width:36px;
  height:36px;
  color:#fff;
}
.rotateCameraButton {
  width:20px;
  height:20px;
  color:#fff;
}
.progressBarBridge{
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: -5px;
}
.progressBarLevelBridge {
  width: 1%;
  height: 3px;
  max-height: 3px;
  min-height: 3px;
  background-color: #02889d;
}
.videoBridgeWrapper button:focus {
  outline: 0px !important;
}
.videoBridgeWrapper button:before {
  // outline: 0px !important;
  background: transparent !important;
}
.toggleGiveWordBlink {
  animation: blink 1s linear infinite;
}
  #waveCallingBridge {
    position: relative;
    margin-top: 25px;
    .dot {
      background: #02889d;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
    }
    .dot:nth-child(2) {
      animation-delay: -1.1s;
    }
    .dot:nth-child(3) {
      animation-delay: -0.9s;
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }
.callingTextBridge{
  position: relative;
  font-weight: normal;
  font-size: 30px;
  margin: 10px 0px;
}
.callingElements {
  display: flex;
  justify-content: center;
}
.callingBar {
  position: absolute;
  height: 36px;
  width: 100%;
  bottom: 0;
  left:0;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.incomingPersonAvatar img {
  position: relative !important;
  border-radius: 10px !important;
  width: 40% !important;
}
.incomingPersonAvatar {
  position: absolute;
  width: 45% !important;
  height: 45% !important;
  margin: auto;
  bottom: 70px;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 575px) {
  html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }
}
@media only screen
  and (min-device-width: 810px)
  and (max-device-width: 1080px)
  and (-webkit-min-device-pixel-ratio: 2) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}
@media only screen
  and (min-device-width: 834px)
  and (max-device-width: 1194px)
  and (-webkit-min-device-pixel-ratio: 2) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}

@media only screen
  and (min-device-width: 834px)
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {

   html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}
// @media only screen
//   and (min-device-width: 800px)
//   and (max-device-width: 1280px)
//   and (-webkit-min-device-pixel-ratio: 1) {

//     html,
//   body,
//   #app,
//   #sandbox,
//   .v-application--wrap,
//   .v-main, .wallpaper, .contentContainer {
//     height: 100% !important;
//     min-height: 100% !important;
//   }
//   #confWrapper{
//     height: 100% !important;
//     #video-container{
//       height: 100% !important;
//       // div{
//       //   height: 100%;
//       // }
//       .videBridgeContainer{
//         // height: calc(100% - 103px) !important;
//         height: 100% !important;
//         #call-grid-23{
//           padding: 0px !important;
//         }
//         #call-grid-23-2{
//           &.grm{
//             border: none !important;
//           }
//           .callerName{
//             width: 100%;
//             margin: 0px !important;
//             max-width: 100% !important;
//           }
//           video{
//             max-height: 100% !important;
//             position: absolute;
//             top: 29px !important;
//             transform: none !important;
//             width: 100% !important;
//             object-fit: cover !important;
//             border: 0px;
//             left: 0px;
//             height: calc(100% - 62px) !important;
//           }
//         }
//         #call-grid-23-1{

//           position: absolute;
//           bottom: 48px;
//           max-width: 30%;
//           max-height: 25%;
//           right: 8px;
//           height: 25%;
//           width: 30%;
//           video{
//             max-height: 100%;
//             max-width: 100%;
//             width: fit-content;
//             height: fit-content;
//             position: absolute;
//             object-fit: cover;
//             // max-width: 100%;
//             // // max-height: 100%;
//             // max-height: 23vh;
//             // height: auto;
//             // width: 100%;
//           }
//            .topbar{
//             display: none;
//           }
//           .userImageBridge{
//             width: fit-content;
//             height: fit-content;
//             position: absolute;
//             object-fit: cover;
//             left: 0;
//             top: 0;
//           }

//         }
//         .userImageBridge{
//           max-width: 100%;
//           max-height: 100%;
//         }

//       }
//     }
//   }

// }
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {

    html,
  body,
  #app,
  #sandbox,
  .v-application--wrap,
  .v-main, .wallpaper, .contentContainer {
    height: 100% !important;
    min-height: 100% !important;
  }
  #confWrapper{
    height: 100% !important;
    #video-container{
      height: 100% !important;
      // div{
      //   height: 100%;
      // }
      .videBridgeContainer{
        // height: calc(100% - 103px) !important;
        height: 100% !important;
        #call-grid-23{
          padding: 0px !important;
        }
        #call-grid-23-2{
          &.grm{
            border: none !important;
          }
          .callerName{
            width: 100%;
            margin: 0px !important;
            max-width: 100% !important;
          }
          video{
            max-height: 100% !important;
            position: absolute;
            top: 29px !important;
            transform: none !important;
            width: 100% !important;
            object-fit: cover !important;
            border: 0px;
            left: 0px;
            height: calc(100% - 62px) !important;
          }
        }
        #call-grid-23-1{

          position: absolute;
          bottom: 48px;
          max-width: 30%;
          max-height: 25%;
          right: 8px;
          height: 25%;
          width: 30%;
          video{
            max-height: 100%;
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            // max-width: 100%;
            // // max-height: 100%;
            // max-height: 23vh;
            // height: auto;
            // width: 100%;
          }
           .topbar{
            display: none;
          }
          .userImageBridge{
            width: fit-content;
            height: fit-content;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
          }

        }
        .userImageBridge{
          max-width: 100%;
          max-height: 100%;
        }

      }
    }
  }

}

</style>
<style scoped lang="scss">
.nameModerator {
  margin-left: 84px !important;
}
.nameNormal {
  margin-left: 30px !important;
}

.greenButton{
  color: #008000;
}
.dbtnsAttendees{
  position: absolute;
  right: 5px;
  top: 13px;
}
.listAttendees{
  position: absolute;
  top: -215px;
  height: 200px;
  font-size: 14px !important;
  max-height: 200px;
  overflow: auto;
  background-color: #ffffffd4 !important;
  z-index: 9999999;
  color: black;
  list-style: none;
  padding: 15px;
  width: 300px;
  max-width: 300px;
  text-align: left;
}
.listDocuments{
  position: absolute;
  top: -215px;
  height: 200px;
  font-size: 14px !important;
  max-height: 200px;
  overflow: auto;
  background-color: #ffffffd4 !important;
  z-index: 9999999;
  color: black;
  list-style: none;
  padding: 15px;
  width: 200px;
  max-width: 200px;
  text-align: left;
}
.inputTopbar{
  color: black;
  background-color: white;
  border-radius: 4px;
}
.companyLogo{
  position: absolute;
  z-index: 9999999;
  display: block;
  width: 40px;
  height: 40px;
  top: 35px;
  right: 5px;
}
.microphoneSlashOverlay{
  position: absolute;
  z-index: 9999999;
  display: block;
  width: 40px;
  height: 40px;
  bottom: 45px;
  right: 20px;
}
.microphone-slash-overlay-blink{
    animation: blink 1s linear infinite;
}
.toggleGiveWordBadge {
    position: absolute;
    top: -10px;
    right: -2px;
    padding: 0px 6px;
    border-radius: 50% !important;
    background: var(--v-primary-base) !important;
    color: white;
}
.userIconInfo  {
  position: absolute;
  // left: 5px;
  left: 15px;
  top: 5px;
  font-size: 15px;
}
.moderatorIconInfo {
  position: absolute;
  // left: 5px;
  left: 15px;
  top: 5px;
}
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
.imgButton {
  width: 33px;
}
.vid2 video {
  left: 0;
}
.tile {
  width: 100%;
  display: table;
  // height: 100%;
  height: 100vh;
}
.localUserImage {
  width: 100%;
  height: 100%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.userImageBridge {
  height: auto;
}
// .gridWrapperNormalMode:fullscreen {
//   height: calc(50vh - 15px) !important;
// }
#confWrapper
.videBridgeContainer {
  z-index: 999;
  width: 100%;
  // height: calc(100vh - 151px);
  &.withoutTimeLine{
    height: 100%;
  }
  &.withTimeLine{
    height: calc(100vh - 151px);
  }
}
.bridgeVideoRemote {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.bridgeVideoLocal {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.localContainer {
  position: relative;
  background: black;
  margin: 5px;
}
.remoteContainerChild {
  position: relative;
  background: black;
  margin: 5px;
}
.remoteContainer {
  display: contents;
}
@import "~bootstrap/scss/bootstrap";
html,
body {
  height: 100%;
}
.bgred {
  background: #dddddd;
}
.bggreen {
  background: #aaaaaa;
}
.bgblue {
  background: blue;
}
.bgyellow {
  background: #888888;
}
.hidd {
  display: none;
}
.grm {
  border: solid 4px white;
  margin: 0px;
}
div {
  font-size: 20px;
}
.title {
  position: relative;
}
.topbar {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  height: 30px;
  font-size: 15px;
  padding: 4px;
  & .callerName{
    max-width: 80%;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.iconBarLocalStream{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.iconbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
  z-index: 1;
  opacity:0;
}
.vid1 video,
.vid1 .avatar {
  max-height: calc((100vh - 205px - 8px) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid2 video,
.vid2 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.confWrapper:fullscreen .gridWrapperNormalMode
.vid1 video, .vid1 .avatar,
.vid2 video, .vid2 .avatar,
.vid3 video, .vid3 .avatar,
.vid3_2 video, .vid3_2 .avatar,
.vid4 video, .vid4 .avatar,
.vid4_2 video, .vid4_2 .avatar,
.vid4_3 video, .vid4_3 .avatar,
.vid6 video, .vid6 .avatar,
.vid6_4 video, .vid6_4 .avatar,
.vidR1 video, .vidR1 .avatar,
.vidR2 video, .vidR2 .avatar,
.vidR3 video, .vidR3 .avatar,
.vidR3_2 video, .vidR3_2 .avatar,
.vidR4 video, .vidR4 .avatar,
.vidR4_2 video, .vidR4_2 .avatar,
.vidR4_3 video, .vidR4_3 .avatar,
.vidR6 video, .vidR6 .avatar,
.vidR6_4 video, .vidR6_4 .avatar
 {
  max-height: 100% !important;

}
#call-grid-23-1{
  .companyLogo{
    .companyLogoBridge.avatar{
      max-height: 100% !important;
    }
  }
}
.vid3 video,
.vid3 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 3) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid3_2 video,
.vid3_2 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 3 * 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid4 video,
.vid4 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 4) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid4_2 video,
.vid4_2 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 4 * 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid4_3 video,
.vid4_3 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 4 * 3) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid6 video,
.vid6 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 6) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vid6_4 video,
.vid6_4 .avatar {
  max-height: calc(((100vh - 205px - 8px) / 6 * 4) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR1 video,
.vidR1 .avatar {
  max-height: calc((100vh - 8px) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR2 video,
.vidR2 .avatar {
  max-height: calc(((100vh - 8px) / 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR3 video,
.vidR3 .avatar {
  max-height: calc(((100vh - 8px) / 3) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR3_2 video,
.vidR3_2 .avatar {
  max-height: calc(((100vh - 8px) / 3 * 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR4 video,
.vidR4 .avatar {
  max-height: calc(((100vh - 8px) / 4) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR4_2 video,
.vidR4_2 .avatar {
  max-height: calc(((100vh - 8px) / 4 * 2) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR4_3 video,
.vidR4_3 .avatar {
  max-height: calc(((100vh - 8px) / 4 * 3) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR6 video,
.vidR6 .avatar {
  max-height: calc(((100vh - 8px) / 6) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}
.vidR6_4 video,
.vidR6_4 .avatar {
  max-height: calc(((100vh - 8px) / 6 * 4) - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: contain;
  border: 0px;
  left: 0px;
}

// sidebar content
.sidenav {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: calc(100% - 66px);
    margin-top: 30px;
    margin-bottom: -5px;
    width: 35px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    overflow-y: hidden;
}
.togglePlay{
  margin-top: 10px;
}
.toggleSettings{
  margin-top: 0px;
}
.recorderOn {
  border: solid 2px red;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.tooltipped:hover .tooltippedtooltip {
    display: block;
}
.tooltippedButtonMenu:hover .tooltippedtooltipButton {
    display: block;
}
.tooltippedtooltip, .tooltippedtooltipButton {
  font-size: 14px !important;
  border-radius: 4px;
  color: #fff;
  display: none;
  background: rgba(97, 97, 97, 0.9);
  margin-left: 77px;
  padding: 10px;
  position: fixed;
  margin-top: -72px;
  z-index: 1000;
  width: auto;
  pointer-events: none;
  padding: 5px 16px;
  text-transform: none;
  font-family: Roboto,sans-serif;
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 0 !important;
}
.sideSettings {
  margin-left: 215px !important;
}
.sideViewRec{
  margin-left: 174px !important
}
.sidePlayStop {
 margin-left: 133px !important;
}
.sideStopStream {
  margin-left: 181px !important;
}
.ttcamera{
  margin-left: 0px !important;
}
.ttmike{
  margin-left: 0px !important;
}
.ttmssg{
  margin-left: 0px !important;
}
.ttemail{
  margin-left: 0px !important;
}
.ttphone{
  margin-left: 0px !important;
}
.ttadmin{
  margin-left: 0px !important;
}
.ttfulls{
  margin-left: 0px !important;
}
.ttshare{
  margin-left: 0px !important;
}
.ttpresent{
  margin-left: 0px !important;
}
.ttdoor{
  margin-left: -23px !important;
  margin-top: -53px !important;
}
.ttside{
    margin-top: 3px !important;
   // margin-left: 154px !important;
  // margin-top: -50px !important;
  // margin-left: 79px !important;
}
.qualityLevel {
    position: absolute;
    // background: red;
    color: red;
    bottom: 42px;
    z-index: 9;
    left: 43px;
    padding: 10px;
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
.hiddenInFullScreen{
  display: none;
}
@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }
}
.fadeOut {
  opacity: 1;
  transition-duration: 150ms;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
